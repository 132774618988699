import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getNotificationTypeList = (clinicId: string) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'notification/getnotificationtypelist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        clinicid: clinicId
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createNotificationTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'notification/createnotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getNotificationTemplateList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'notification/getnotificationtemplatelist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateActiveStatusNotificationTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'notification/updatenotificationtemplatestatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

/* Follwup */
export const createNotificationTemplateFollowup = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'followup/createfollowup',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getNotificationTemplateListFollowup = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'followup/getfollowuplist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateActiveStatusNotificationTemplateFollowup = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'followup/updatefollowupstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateActiveStatusEmergencyTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'notification/updateemegencynotificationstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const uploadPhoto = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'messagecenter/uploadimages',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true, true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createCampaign = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/createcampaign',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createManualCampaign = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/createmanualcampaign',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaigntemplate/createcampaigntemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const campaignList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/getcampaignlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const manualCampaignList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/getmanualcampaignlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const templateList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaigntemplate/getcampaigntemplatelist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const patientList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/getcampignpatientlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updatePatientStatus = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/updatecampignpatientstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateNotificationDetail = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'notification/updatenotificationDetail',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateNotificationDetailFollowup = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'followup/updatefollowupdetail',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const uploadPhotos = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'messagecenter/uploadimages',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateCampaignStatus = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/updatecampaignstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateManualCampaignStatus = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/updatemanualcampaignstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateManualCampaignPatientStatus = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/updatemanualsignincampignstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateManualCampaignImportedPatientStatus = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/sendmanualimportedpatient',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};


export const updateTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaigntemplate/updatecampaigntemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updateCampaign = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/updatecampaign',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updateManualCampaign = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/updatemanualcampaign',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getNotificationPatientList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/getnotificationpatientlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updateNotificationPatientList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/updatenotificationstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

/* Followup */

export const getNotificationPatientListFollowup = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/getfollowuppatientlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updateNotificationPatientListFollowup = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/updatefollowuppatientstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const administrativeNotificationTypeList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'administrativenotification/getadministrativenotificationtype',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createAdministrativeNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'administrativenotification/createadministrativenotification',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const administrativeNotificationList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'administrativenotification/getadministrativenotificationlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updateAdministrativeNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'administrativenotification/updateadministrativenotification',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const administrativePatientsList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/getadministrativepatientlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const administrativePatientStatusChange = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/updateadministrativepatientstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getDigitalDocNotificationList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/getdigitaldocnotificationlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getDigitalDocType = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/getdigitaldoctype',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const createDigitalDocNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/createdigitaldocnotification',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updateDigitalDocNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/updatedigitaldocnotification',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const createDocument = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/createdocument',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getDocumentList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/getdocumentlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const deleteDocument = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/deletedocument',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updateDocumentStatus = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/updatedocumentstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const uploadPDF = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'messagecenter/uploadpdf',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const getDigitalFormList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/getclinicwisedocumentlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const sendDigitalDocEmail = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/senddigitaldocemail',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const sendDigitalSignatureEmail = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/senddigitalsignatureemail',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const deleteNotificationTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'notification/deletenotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deleteNotificationTemplateFollowup = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'followup/deletefollowuptemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deleteCampaign = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/deletecampaign',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deleteManualCampaign = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/deletemanualcampaign',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deleteTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaigntemplate/deletecampaigntemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deleteAdministrativeNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'administrativenotification/deleteadministrativenotification',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deleteDigitalDocumentNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'digitaldocnotification/deletedigitalnotification',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const sendSMS = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/sendmanualsms',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const sendSMSChat = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/sendpatientmanualsms',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

// Payment Notification
export const paymentNotificationTypeList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/getpaymentnotificationtemplatetype',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createPaymentNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/createpaymentnotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const updatePaymentNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/updatepaymentnotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const paymentNotificationList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/getpaymentnotificationtemplatelist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const deletePaymentNotification = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/deletepaymentnotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

// payment - patient
export const paymentPatientsList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/getpaymentnotificationpatientlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};
export const paymentPatientStatusChange = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'patient/updatepaymentnotificationpatientstatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

// Payment Reminder
export const getPaymentReminderNotificationTemplateList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/getpaymentremindernotificationtemplatelist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateActiveStatusPaymentReminderNotificationTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/updatepaymentremindernotificationtemplatestatus',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deletePaymentReminderNotificationTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/deletepaymentremindernotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updatePaymentReminderNotificationDetail = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/updatepaymentremindernotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createPaymentReminderNotificationTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/createpaymentremindernotificationtemplate',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const paymentReminderNotificationTypeList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'paymentnotification/getpaymentremindertypelist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};
export const getAppointmentCategoryList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/getprocedurecodecategorylist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};
export const getProcedureCodeList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/getprocedurecodelist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const getEmailTemplateList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'emailtemplate/list',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const createEmailTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'emailtemplate/create',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateEmailTemplate = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'emailtemplate/update',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const deleteEmailTemplate = (data: any, templateId: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'emailtemplate/delete/' + templateId,
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const updateEmailTemplateStatus = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'emailtemplate/active',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const sendFax = async (data: any) => {
  const request = {
    url: configs.MESSAGE_CENTER_API + 'messagecenter/sendfaxemail',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getDependentsBasedOnCategoryAndProceedures = (clinicid:any, type:string, code: string, category: string) => {
  const request = {
    url: configs.API_URL + `clinic/${clinicid}/overdue/?type=${type}&code=${code}&category=${category}`,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const getManualPatientList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/signinpatientlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const getImportedManualPatientList = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'campaign/importedpatientlist',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};

export const getAppointmentListAnalytics = (data:any, type: string) => {
  const request = {
    url: configs.MESSAGE_CENTER_API + 'analytics/'+type,
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const setDueImmediatelyAppointmentArchive = (data:any) => {
  const request = {
    url: configs.MESSAGE_CENTER_API + 'analytics/updatedueimmediatearchive',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}

export const previewEmail = (data:any) => {
  const request = {
    url: configs.MESSAGE_CENTER_API + 'messagecenter/previewemail',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
}