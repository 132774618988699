import React, { useEffect, useState } from 'react';
import { IAppState } from '../../store';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { isLogin, getCookie } from '../../utils/cookies';
import { userLogout } from '../../store/login/actions';
import { fetchUserDataPending } from '../../store/userData/actions';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import 'react-toastify/dist/ReactToastify.css';
import Cookie from 'js-cookie';
import { toast } from 'react-toastify';
import { Error404 } from '../../components/Error404';
import { getCurrentUser, signInWithCustomToken } from '../../utils/firebaseUtils';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import configs from '../../configs/apiConfigs';
import { OnboardClinic } from '../../v2/pages/onboardClinic/OnboardClinic';
import { OnboardDoctor } from '../../v2/pages/onboardProvider/OnboardDoctor';
import { Header } from '../../v2/components/header/Header';
import '../../v2/assets/scss/new-ui.scss';
import GetPlan from '../../v2/pages/subscription/GetPlan';
import { fetchLoadingPending } from '../../store/loadingIndicator/actions';
import PageContainer from '../pagecontainer/PageContainer';
import { login } from '../../v2/services/auth';
import { removeCookie } from '../../v2/utils/cookies';
import NotSupported from '../../v2/assets/images/not-supported.png';

const MainContainer = (props: any) => {
    const [_mount, isMount] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const mode = new URLSearchParams(location.search).get("mode");
    const token = new URLSearchParams(location.search).get("token");
    const userDataResponse: any = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    useEffect(() => {
        if (mode === "desktop" && token &&  token !== "") {
            const url = window.location.pathname + "?mode="+mode;
            console.log(window.location);
            console.log(isLogin());
            if (!isLogin()) {
                onSubmit({token: token}, url);
            } else {
                removeCookie();
                dispatch(userLogout());
                onSubmit({token: token}, url);
            }
            return;
        }
        if (mode === "desktop") {
            const clinic_cookie = localStorage.getItem('clinic_cookie');
            if (clinic_cookie) {
                dispatch(fetchUserDataPending({}));
            } else {
                dispatch(userLogout());
                history.replace('/login');
            }
            return;
        }
        if (Cookie.getJSON('clinic_cookie') === undefined || Cookie.getJSON('clinic_cookie') === null) {
            // toast.warn('Please check cookies are enabled or not, we require cookie to use the website features.');
            history.replace('/login');
        } else if (isLogin()) {
            if (!getCurrentUser()) {
                doTask();
            } else {
                firebase.firestore().collection(configs.CHAT_COLLECTION).orderBy("createdAt", "desc").limit(1).get().then((doc) => {
                    dispatch(fetchUserDataPending({}));
                }).catch((error) => {
                    dispatch(userLogout());
                    history.replace('/login');
                });
            }
        }
    }, []);
    useEffect(() => {
        if (userDataResponse && userDataResponse.status.error === false && userDataResponse.status.msg !== "") {
            isMount(true);
            if (userDataResponse.userAccess === "1" && userDataResponse.clinics.length === 0) {
                history.replace('/clinic/create');
            } else if (userDataResponse.userAccess === "1" && userDataResponse.clinics.length > 0 &&
                userDataResponse.clinics[0].onBoarding.status === 0) {
                history.replace('/clinic/create', { step: userDataResponse.clinics[0].onBoarding.step });
            } else if (userDataResponse.userAccess === "1" && userDataResponse.clinics.length > 0 &&
                userDataResponse.clinics[0].onBoarding.status === 1 && userDataResponse.cards.length === 0) {
                history.replace('/clinic/create', { step: "11" });
            } else {
                if (props.location.pathname === '/clinic/create') {
                    history.replace('/myschedule');
                    return;
                }
                let url = props.location.pathname;
                if (props.location.search !== "") {
                    url = url + props.location.search;
                }
                if (mode === "desktop") {
                    const url = window.location.pathname + "?mode="+mode;
                    history.replace(url);
                    return;
                }
                history.replace(url);
            }
        }
    }, [userDataResponse]);
    const doTask = async () => {
        try {
            dispatch(fetchLoadingPending());
            await signInWithCustomToken(getCookie().jwtToken).then((signInSuccess) => {
                // sample query to check connection with firestore
                firebase.firestore().collection(configs.CHAT_COLLECTION).orderBy("createdAt", "desc").limit(1).get().then((doc) => {
                    dispatch(fetchUserDataPending({}));
                }).catch((error) => {
                    dispatch(userLogout());
                    history.replace('/login');
                });
            });
        } catch (err) {
            dispatch(userLogout());
            history.replace('/login');
        }
    }
    const onSubmit = (data: any, url:string) => {
        login({ token: data.token }).then((success) => {
            if (success && success.status && success.status.error === false) {
                signInWithCustomToken(success.jwtToken).then((resp) => {
                    Cookie.remove('clinic_cookie'); Cookie.remove('clinic_cookie', { domain: configs.COOKIE_DOMAIN });
                    const key1 = 'xprUser.clinic-' + success.id;
                    const key2 = 'xpr_user_id';
                    const key3 = 'xprAuthUser';
                    const key4 = 'jwtToken';
                    var json: any = {};
                    json[key1] = true;
                    json[key2] = success.uniqueId;
                    json[key3] = success.authParam;
                    json[key4] = success.jwtToken;
                    Cookie.set('clinic_cookie', json, { expires: 30 });
                    Cookie.set('clinic_cookie', json, { domain: configs.COOKIE_DOMAIN, url: '/', expires: 30 });
                    localStorage.setItem('clinic_cookie', JSON.stringify(json));
                    window.location.href = url;
                }).catch((error) => {
                    console.log(error);
                });
            }
        }).catch((err) => {
            console.log("err", err);
        });
    };
    return (
        <>
            <div className={"d-flex d-lg-none not-supported-box justify-content-center "+ (mode && mode=='desktop' ? 'no-not-supported': '')}>
                <div className="align-self-center">
                    <img src={NotSupported} className="img-fluid mb-5" />
                    <p className="text-center px-4">Oops !!! View for this resolution is not supported. Please use an iPad or Android tablet in landscape mode or Desktop to access Clinic ADMIN panel features.</p>
                </div>
            </div>
            <div className={mode && mode=='desktop' ? "app": "app d-none d-lg-block" }>
                <LoadingIndicator></LoadingIndicator>
                {
                    (_mount) &&
                    <div className="layout">
                        {
                            (props.location.pathname !== '/clinic/create' &&
                                props.location.pathname !== '/doctor/create' && props.location.pathname !== '/subscription/upgrade/plan') && mode !== "desktop" &&
                            <Header></Header>
                        }
                        <Switch>
                            <Route exact path="/404" component={Error404}></Route>
                            <Route exact path="/clinic/create" component={OnboardClinic}></Route>
                            <Route exact path="/doctor/create" component={OnboardDoctor}></Route>
                            <Route exact path="/subscription/upgrade/plan" component={GetPlan}></Route>
                            <Route path="/" component={PageContainer}></Route>
                        </Switch>
                    </div>
                }
            </div>
        </>
    );
}
export default MainContainer;