import { Slider } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { createCampaign, getAppointmentCategoryList, getProcedureCodeList, templateList, updateCampaign } from '../../services/messageCenter';
import Select from 'react-select';
import WarningIcon from '../../assets/images/warning-icon.svg';
export const CreateCampaign = forwardRef((props: any, ref) => {
  const [show, setShow] = useState(false);
  const [modalData, setModalData]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates]: any = useState([]);
  const userDataResponse = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const [appointmentCategories, setAppointmentCategories]: any = useState([]);
  const [selectedAppointmentCategories, setSelectedAppointmentCategories] = useState([]);
  const [procedureCodes, setProcedureCodes]: any = useState([]);
  const [selectedProcedurecodes, setSelectedProcedurecodes] = useState([]);
  const { register, handleSubmit, errors, setError, getValues, setValue, control, watch } = useForm({
    defaultValues: {
      category: 'recall',
      campaignName: '',
      template: '',
      email: '',
      text: '',
      overduepatient: '0,0',
      initiationDays: 120,
      eventDate: moment().toDate(),
      startTime: moment().format('LT'),
      endTime: moment().format('LT'),
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      healthrating: '',
      financialrating: '',
      activeCampaign: false,
      retarget: 'weekly',
      reoccurencelimit: 1,
      insurance: 'all',
      guarantor: 'all',
      // appointmentstatus:'scheduled'
      appointmentcategory: '',
      procedurecodes: '',
      appointmentcategorytype: 'none',
      procedurecodestype: 'none',
      eventcampign_type: 'all'
    }
  });
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    showModal: showModal,
    hideModal: hideModal
  }));
  const showModal = (item: any = null) => {
    setShow(true);
    setModalData(item);
  };
  const hideModal = () => { setShow(false) };
  const onSubmit = (data: any) => {
    let templateTypes = '';
    if (data.email) {
      templateTypes = templateTypes + 'email,';
    }
    if (data.text) {
      templateTypes = templateTypes + 'text';
    }
    const request = {
      clinicid: userDataResponse.clinics[0].id,
      title: data.campaignName,
      messagecentercampaigntemplate_id: data.template,
      categorytype: data.category,
      templatetypes: templateTypes,
      overduepatients: data.overduepatient,
      healthrating: data.healthrating,
      financialrating: data.financialrating,
      retarget: data.retarget,
      isactive: data.activeCampaign,
      reoccurencelimit: data.reoccurencelimit
    }
    if (categoryWatch === 'event' || categoryWatch === 'signinpatient') {
      Object.assign(request, {
        eventdate: data.eventDate,
        eventstarttime: data.startTime,
        eventendtime: data.endTime
      });
    }
    if (categoryWatch === 'recall') {
      let appointmentCategoryTypeWatch = watch('appointmentcategorytype');
      let procedureCodesTypeWatch = watch('procedurecodestype');
      let procedure_categoryTemp: any = '', procedure_codeTemp: any = '';

      // appointment categories
      if (appointmentCategoryTypeWatch === 'all') {
        procedure_categoryTemp = 'all';
      } else if (appointmentCategoryTypeWatch === 'none') {
        procedure_categoryTemp = 'none';
      } else {
        procedure_categoryTemp = data.appointmentcategory.toString();
      }

      // Procedure code
      if (procedureCodesTypeWatch === 'all') {
        procedure_codeTemp = 'all';
      } else if (procedureCodesTypeWatch === 'none') {
        procedure_codeTemp = 'none';
      } else {
        procedure_codeTemp = data.procedurecodes.toString();
      }

      Object.assign(request, {
        initialdays: data.initiationDays,
        reoccurencelimit: data.reoccurencelimit,
        // appointmentstatus: data.appointmentstatus,
        insurance: data.insurance,
        guarantor: data.guarantor,
        procedure_category: procedure_categoryTemp,
        procedure_code: procedure_codeTemp
      });
    }
    if (categoryWatch === 'event') {
      Object.assign(request, {
        eventcampign_type: data?.eventcampign_type,
        eventcampign_type_start_date: (data?.eventcampign_type === 'active' || data?.eventcampign_type === 'inactive') ? data?.startDate : undefined,
        eventcampign_type_end_date: (data?.eventcampign_type === 'active' || data?.eventcampign_type === 'inactive') ? data?.endDate : undefined,
        insurance: data?.eventcampign_type === 'insurance' ? data?.insurance : undefined
      })
    }
    setIsLoading(true);
    if (modalData && modalData.item) {
      Object.assign(request, { messagecentercampaign_id: modalData.item.messagecentercampaign_id });
      updateCampaign(request).then((success) => {
        setIsLoading(false);
        if (success && success.error === false) {
          toast.success('Campaign update successfully.');
          hideModal();
          props.fetchList();
        }
      }).catch((err) => {
        setIsLoading(false);
        toast.error('Something went wrong');
      })
      return;
    }
    createCampaign(request).then((success) => {
      setIsLoading(false);
      if (success && success.error === false) {
        toast.success('Campaign create successfully.');
        hideModal();
        props.fetchList();
      }
    }).catch((err) => {
      setIsLoading(false);
      toast.error('Something went wrong');
    })
  };
  function valueTextInitiationDays(value: any) {
    return `${value} Days`;
  }
  const categoryWatch: any = watch('category');

  const fetchAppointmentCategoryListOnEdit = () => {
    dispatch(fetchLoadingPending());
    getAppointmentCategoryList({
      clinicid: userDataResponse.clinics[0].id,
    }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false) {
        setAppointmentCategories(success.data);
        if (modalData && modalData.item) {
          let procedure_code_categoryTemp: any = '';
          // Appointment category
          if (modalData.item.procedure_category === 'all') {
            setValue('appointmentcategorytype', 'all');
            let values: any = [];
            for (let i = 0; i < success.data.length; i++) {
              values.push(success.data[i].procedure_code_category)
            }
            setValue('appointmentcategory', values);
            fetchProcedureCodeOnEdit('all', []);
          } else if (modalData.item.procedure_code === 'none') {
            setValue('appointmentcategorytype', 'none');
            setValue('appointmentcategory', []);
          } else {
            setValue('appointmentcategorytype', 'custom');
            if (modalData.item && modalData.item.procedure_category) {
              procedure_code_categoryTemp = modalData.item.procedure_category.split(",");
            } else {
              procedure_code_categoryTemp = [];
            }
            setValue('appointmentcategory', procedure_code_categoryTemp);
            fetchProcedureCodeOnEdit('custom', procedure_code_categoryTemp);
          }
        }
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
    })
  }

  const fetchProcedureCode = () => {
    dispatch(fetchLoadingPending());
    getProcedureCodeList({
      clinicid: userDataResponse.clinics[0].id,
      category: watch('appointmentcategory'),
      type: watch('appointmentcategorytype')
    }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false) {
        if (success.data && success.data[0]) {
          setProcedureCodes(success.data[0]);
          if (watch('procedurecodestype') === 'all') {
            let values: any = [];
            for (let i = 0; i < success.data[0].length; i++) {
              values.push(success.data[0][i].procedure_code_sikkaId)
            }
            setValue('procedurecodes', values);
          }
        }
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
    })
  }
  const fetchProcedureCodeOnEdit = (type: any, categories?: any) => {
    dispatch(fetchLoadingPending());
    getProcedureCodeList({
      clinicid: userDataResponse.clinics[0].id,
      category: categories ? categories : watch('appointmentcategory'),
      type: type
    }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false) {
        if (success.data && success.data[0]) {
          setProcedureCodes(success.data[0]);
          if (modalData && modalData.item) {
            let procedure_codeTemp: any = '';
            // procedure code
            if (modalData.item.procedure_code === 'all') {
              setValue('procedurecodestype', 'all');
              let values: any = [];
              for (let i = 0; i < success.data[0].length; i++) {
                values.push(success.data[0][i].procedure_code_sikkaId)
              }
              setValue('procedurecodes', values);
            } else if (modalData.item.procedure_code === 'none') {
              setValue('procedurecodestype', 'none');
              setValue('procedurecodes', []);
            } else {
              setValue('procedurecodestype', 'custom');
              procedure_codeTemp = modalData.item.procedure_code.split(",");
              setValue('procedurecodes', procedure_codeTemp);
            }
          }
        }
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
    })
  }

  const onChnageAppointmentCategory = () => {
    if (watch('appointmentcategory') && watch('appointmentcategory').length > 0) {
      fetchProcedureCode();
    }
  }

  useEffect(() => {
    if (modalData && modalData.item && categoryWatch && modalData.item.categorytype !== categoryWatch) { return; }
    if (categoryWatch && show) {
      dispatch(fetchLoadingPending());
      templateList({
        clinicid: userDataResponse.clinics[0].id,
        category: categoryWatch
      }).then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.error === false) {
          setTemplates(success.data);
        }
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      })
      if (categoryWatch === 'recall' && modalData === null) {
        setValue('procedurecodes', []);
        setValue('procedurecodestype', 'none');
        setProcedureCodes([]);
        dispatch(fetchLoadingPending());
        getAppointmentCategoryList({
          clinicid: userDataResponse.clinics[0].id,
        }).then((success) => {
          dispatch(fetchLoadingSuccess(false));
          if (success && success.error === false) {
            setAppointmentCategories(success.data);
          }
        }).catch((err) => {
          dispatch(fetchLoadingSuccess(false));
        })
      }
    }
  }, [categoryWatch, show]);
  useEffect(() => {
    console.log("Modal datat", modalData);
    if (modalData && modalData.item) {
      setValue('campaignName', modalData.item.title);
      setValue('category', modalData.item.categorytype);
      if (modalData.item.categorytype === 'recall') {
        setValue('insurance', modalData.item.insurance);
        setValue('guarantor', modalData.item.guarantor);
        // if (modalData.item.procedure_category) {
        fetchAppointmentCategoryListOnEdit()
        // }
        // setValue('appointmentcategory', modalData.item.procedure_category.split(","));
        // setValue('procedurecodes', modalData.item.procedure_code.split(","));
        // setValue('appointmentstatus', modalData.item.appointment_status);
      }
      setValue('template', modalData.item.messsagecentercampaigntemplate_id);
      setValue('email', modalData.item.templatetypes.includes("email"));
      setValue('text', modalData.item.templatetypes.includes("text"));
      setValue('overduepatient', modalData.item.overduepatients);
      if (modalData.item.categorytype === 'recall') {
        setValue('initiationDays', modalData.item.initialdays);
      }
      if (modalData.item.categorytype === 'event') {
        setValue('eventDate', moment(modalData.item.eventdate));
        setValue('startTime', moment(modalData.item.eventstartdate));
        setValue('endTime', moment(modalData.item.eventenddate));

        setValue('eventcampign_type', modalData.item.eventcampign_type);
        setValue('startDate', moment(modalData.item.eventcampign_type_start_date));
        setValue('endDate', moment(modalData.item.eventcampign_type_end_date));
        setValue('insurance', modalData.item.insurance);
      }
      setValue('healthrating', modalData.item.healthrating);
      setValue('financialrating', modalData.item.financialrating);
      setValue('activeCampaign', modalData.item.isactive);
      setValue('retarget', modalData.item.retarget);
      setValue('reoccurencelimit', modalData.item.reoccurencelimit ? modalData.item.reoccurencelimit : 1);
    }
  }, [modalData]);

  const handleOnChnageAll = () => {
    if (watch('appointmentcategorytype') === 'all') {
      let values: any = [];
      for (let i = 0; i < appointmentCategories.length; i++) {
        values.push(appointmentCategories[i].procedure_code_category)
      }
      setValue('appointmentcategory', values);
      fetchProcedureCode();
    }
  }

  const handleOnChangeNone = () => {
    if (watch('appointmentcategorytype') === 'none') {
      setValue('appointmentcategory', []);
      setValue('procedurecodestype', 'none');
      setValue('procedurecodes', []);
      setProcedureCodes([]);
    }
  }

  const handleOnChangeCustom = () => {
    setValue('appointmentcategory', []);
  }

  useEffect(() => {
    let type = watch('procedurecodestype');
    if (type === 'all' && procedureCodes.length > 0) {
      let values: any = [];
      for (let i = 0; i < procedureCodes.length; i++) {
        values.push(procedureCodes[i].procedure_code_sikkaId)
      }
      setValue('procedurecodes', values);
    } else if (type === 'none') {
      setValue('procedurecodes', []);
    } else {
      setValue('procedurecodes', []);
    }
  }, [watch('procedurecodestype')]);
  return (
    <Modal className="camp-popups-sec" show={show} onHide={hideModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal d-block position-relative">
          <div className="modal-header">
            <div>
              <h5 className="modal-title mb-0">{modalData?.item?.isactive ? 'View campaign' : 'Create campaign'}</h5>
            </div>
          </div>
          <div className="modal-body">
            <div className={"input-box"}>
              <div className="row mb-4">
                <div className="col-6">
                  <label htmlFor="campaignName" className="form-label fw-bold">Title</label>
                  <input disabled={modalData?.item?.isactive} type="text" className="form-control" id="campaignName" name="campaignName" placeholder="Campaign title" ref={register({
                    required: true,
                  })} />
                  <small className="text-muted">ex. recall reminder.</small>
                  {
                    (errors && errors.campaignName && errors.campaignName.type === "required") &&
                    < div className="is-invalid">Campaign Name is required</div>
                  }
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label htmlFor="category" className="form-label fw-bold">Feature Category</label>
                  <select disabled={modalData?.item?.isactive} className="form-select" aria-label="Select category" id="category" name="category" ref={register({ required: true })}>
                    <option value="recall">Re-call</option>
                    <option value="event">Event</option>
                    <option value="birthday">Birthday</option>
                    <option value="signinpatient">Signin Patient</option>
                  </select>
                  {
                    (errors && errors.category && errors.category.type === "required") &&
                    < div className="is-invalid">Category is required</div>
                  }
                </div>
                <div className="col-6">
                  <label htmlFor="template" className="form-label fw-bold">Template</label>
                  <select disabled={modalData?.item?.isactive} className="form-select" aria-label="Select template" name="template" defaultValue={templates.length > 0 ? templates[0].messagecentercampaigntemplate_id : ''} ref={register({ required: true })}>
                    {
                      templates.map((item: any, index: number) => (
                        <option value={item.messagecentercampaigntemplate_id} key={index + item.messagecentercampaigntemplate_id}>{item.name}</option>
                      ))
                    }
                  </select>
                  {
                    (templates && templates.length === 0) &&
                    <label>No templates found, please add by visting <NavLink to="/message-center/campaigns/templates">Add Template</NavLink></label>
                  }
                  {
                    (errors && errors.template && errors.template.type === "required") &&
                    <div className="is-invalid">Template is required</div>
                  }
                  {/* <button type="button" className="btn text-primary float-end px-0">Create new template</button> */}
                </div>
              </div>
              {
                (watch('category') === 'recall') &&
                <>
                  <div className="row mb-4">
                    <div className="col-6">
                      <label htmlFor="category" className="form-label fw-bold">Insurance</label>
                      <select disabled={modalData?.item?.isactive} className="form-select" aria-label="Select Insurance" id="insurance" name="insurance" ref={register()}>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="all">All Patient</option>
                      </select>
                    </div>
                    <div className="col-6">
                      <label htmlFor="category" className="form-label fw-bold">Guarantor</label>
                      <select disabled={modalData?.item?.isactive} className="form-select" aria-label="Select Guarantor" id="guarantor" name="guarantor" ref={register()}>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="all">All Patient</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      <label htmlFor="category" className="form-label fw-bold">Appointment Category</label>
                      {/* <Select className="select2 myslt" name="appointmentcategory" options={appointmentCategories} isMulti
                        value={selectedAppointmentCategories}
                        onChange={(e: any) => { setSelectedAppointmentCategories(e) }}
                      ></Select> */}
                      <div>
                        <div className="form-check form-check-inline">
                          <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="appointmentcategorytype" id="allcategory" value="all" ref={register} onChange={() => { handleOnChnageAll() }} />
                          <label className="form-check-label" htmlFor="allcategory">All</label>
                        </div>
                        <div className="form-check form-check-inline d-none">
                          <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="appointmentcategorytype" id="nonecategory" value="none" ref={register} onChange={() => { handleOnChangeNone() }} />
                          <label className="form-check-label" htmlFor="nonecategory">None</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="appointmentcategorytype" id="customcategory" value="custom" ref={register} onChange={() => { handleOnChangeCustom() }} />
                          <label className="form-check-label" htmlFor="customcategory">Custom</label>
                        </div>
                      </div>
                      <select disabled={watch('appointmentcategorytype') === 'all' || watch('appointmentcategorytype') === 'none' || modalData?.item?.isactive} multiple={true} className="form-control" aria-label="Select Appointment Category" id="appointmentcategory" name="appointmentcategory" ref={register()}
                        onChange={() => { onChnageAppointmentCategory() }}>
                        {
                          (appointmentCategories && appointmentCategories.length > 0) &&
                          appointmentCategories.map((category: any) => {
                            return (
                              <option key={category.procedure_code_category} value={category.procedure_code_category}>{category.procedure_code_category}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-6">
                      <label htmlFor="category" className="form-label fw-bold">Procedure Codes</label>
                      {/* <Select className="select2 myslt" name="procedurecodes" options={procedureCodes} isMulti
                        value={selectedProcedurecodes}
                        onChange={(e: any) => { setSelectedProcedurecodes(e) }}
                      ></Select> */}
                      <div>
                        <div className="form-check form-check-inline">
                          <input disabled={watch('appointmentcategorytype') === 'none' || modalData?.item?.isactive} className="form-check-input" type="radio" name="procedurecodestype" id="allcodetype" value="all" ref={register} />
                          <label className="form-check-label" htmlFor="allcodetype">All</label>
                        </div>
                        <div className="form-check form-check-inline d-none">
                          <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="procedurecodestype" id="nonecodetype" value="none" ref={register} />
                          <label className="form-check-label" htmlFor="nonecodetype">None</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input disabled={watch('appointmentcategorytype') === 'none' || modalData?.item?.isactive} className="form-check-input" type="radio" name="procedurecodestype" id="customcodetype" value="custom" ref={register} />
                          <label className="form-check-label" htmlFor="customcodetype">Custom</label>
                        </div>
                      </div>
                      <select disabled={watch('appointmentcategorytype') === 'none' || watch('procedurecodestype') === 'all' || watch('procedurecodestype') === 'none' || modalData?.item?.isactive} multiple={true} className="form-control" aria-label="Select Procedure Codes" id="procedurecodes" name="procedurecodes" ref={register()}>
                        {
                          (procedureCodes && procedureCodes.length > 0) &&
                          procedureCodes.map((code: any) => {
                            return (
                              <option key={code.procedure_code_sikkaId} value={code.procedure_code_sikkaId}>{code.procedure_code_sikkaId}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="row mb-4 d-none">
                    <div className="col-6">
                      <label htmlFor="category" className="form-label fw-bold">Appointment Status</label>
                      <select disabled={modalData?.item?.isactive} className="form-select" aria-label="Select Appointment Status" id="appointmentstatus" name="appointmentstatus" ref={register()}>
                        <option value="scheduled">Scheduled</option>
                        <option value="completed">Completed</option>
                      </select>
                    </div>
                  </div>
                </>
              }

              {
                <div className={"row mb-4" + (((categoryWatch !== "event" && categoryWatch !== "signinpatient") ? ' d-none' : ''))}>
                  <div className="col-6">
                    <label htmlFor="eventDate" className="form-label fw-bold">Notification Date</label>
                    <Controller
                      name="eventDate"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={moment().toDate()}
                      disabled={modalData?.item?.isactive}
                      render={(props) => (
                        <KeyboardDatePicker
                          format="DD-MM-YYYY"
                          margin="normal"
                          value={props.value}
                          onChange={(date) => props.onChange(date)}
                          disabled={modalData?.item?.isactive}
                          KeyboardButtonProps={{
                            'aria-label': 'change event date',
                          }}
                        />
                      )}
                    ></Controller>
                    {
                      (errors && errors.eventDate && errors.eventDate.type === "required") &&
                      <div className="is-invalid">Date is required</div>
                    }
                  </div>
                  <div className="col-3 d-none">
                    <label htmlFor="startTime" className="form-label fw-bold">Start time</label>
                    <Controller
                      name="startTime"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={moment().toDate()}
                      disabled={modalData?.item?.isactive}
                      render={(props) => (
                        <KeyboardTimePicker
                          margin="normal"
                          id="startTime"
                          label=""
                          value={props.value}
                          onChange={date => props.onChange(date)}
                          disabled={modalData?.item?.isactive}
                          KeyboardButtonProps={{
                            'aria-label': 'change start time',
                          }}
                        />
                      )}
                    ></Controller>
                    {
                      (errors && errors.startTime && errors.startTime.type === "required") &&
                      <div className="is-invalid">Start time is required</div>
                    }
                  </div>
                  <div className="col-3 d-none">
                    <label htmlFor="endTime" className="form-label fw-bold">End time</label>
                    <Controller
                      name="endTime"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={moment().toDate()}
                      disabled={modalData?.item?.isactive}
                      render={(props) => (
                        <KeyboardTimePicker
                          margin="normal"
                          id="endTime"
                          label=""
                          value={props.value}
                          onChange={date => props.onChange(date)}
                          disabled={modalData?.item?.isactive}
                          KeyboardButtonProps={{
                            'aria-label': 'change end time',
                          }}
                        />
                      )}
                    ></Controller>
                    {
                      (errors && errors.endTime && errors.endTime.type === "required") &&
                      <div className="is-invalid">End time is required</div>
                    }
                  </div>
                </div>
              }
              <div className="row mb-4">
                <div className="col-12">
                  <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Notification medium</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="checkbox" id="emailCheckbox" name="email" value="email" ref={register} />
                      <label className="form-check-label" htmlFor="emailCheckbox">Email</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="checkbox" id="textCheckbox" name="text" value="text" ref={register} />
                      <label className="form-check-label" htmlFor="textCheckbox">Text</label>
                    </div>
                  </div>
                  <div className="text-muted small mt-2 d-none"><img src={WarningIcon} className="me-2" />Disable is not configured, please configure email & text in selected template.</div>
                </div>
              </div>
              <hr className="my-1" />
              <div className="my-4 text-muted">Advance settings</div>
              {
                <div className={"row mb-4 d-none" + ((categoryWatch !== 'recall' ? ' d-none' : ''))}>
                  <div className="col-12">
                    <label className="form-label fw-bold">Initiation days</label>
                    <small className="text-muted d-block mb-5">Start automated reminder to over due patients after selected # days</small>
                    <Controller
                      name="initiationDays"
                      control={control}
                      defaultValue={15}
                      disabled={modalData?.item?.isactive}
                      render={(props) => {
                        return (
                          <Slider
                            getAriaValueText={valueTextInitiationDays}
                            aria-labelledby="initiation-days"
                            value={props.value}
                            onChange={(event, value) => {
                              props.onChange(value)
                            }}
                            min={1}
                            max={180}
                            valueLabelDisplay="on"
                            disabled={modalData?.item?.isactive}
                          />
                        )
                      }}
                    ></Controller>
                  </div>
                </div>
              }
              {
                !(categoryWatch === 'birthday' || categoryWatch === 'signinpatient' || categoryWatch === 'event') &&
                <div className={"row mb-4 " + (((categoryWatch === 'birthday' || categoryWatch === 'signinpatient') ? 'd-none' : ''))} >
                  <div className="col-12">
                    <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Over due patients</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="allpatient" value="0,0" ref={register} />
                        <label className="form-check-label" htmlFor="allpatient">All patients</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-6" value="0,6" ref={register} />
                        <label className="form-check-label" htmlFor="month0-6">0-6 months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month6-12" value="6,12" ref={register} />
                        <label className="form-check-label" htmlFor="month6-12">7-12 months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month12-18" value="12,18" ref={register} />
                        <label className="form-check-label" htmlFor="month12-18">13-18 months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month18-24" value="18,24" ref={register} />
                        <label className="form-check-label" htmlFor="month18-24">19-24 months</label>
                      </div>
                    </div>
                  </div>
                </div>
              }
              
              {
                <div className={"row mb-4 " + (categoryWatch !== 'event' ? 'd-none' : '')}>
                  <div className="col-12">
                    <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Event campaign type</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="eventcampign_type" id="allpatient" value="all" ref={register} />
                        <label className="form-check-label" htmlFor="allpatient">All patients</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="eventcampign_type" id="active" value="active" ref={register} />
                        <label className="form-check-label" htmlFor="active">Active patient</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="eventcampign_type" id="inactive" value="inactive" ref={register} />
                        <label className="form-check-label" htmlFor="inactive">Inactive patient</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="eventcampign_type" id="insurance" value="insurance" onClick={() => {
                          setValue('insurance', 'all');
                        }} ref={register} />
                        <label className="form-check-label" htmlFor="insurance">Insurance</label>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {
                <div className={"row mb-4 " + (categoryWatch !== 'event' && 'd-none')}>
                  <>
                    <div className={"col-3 " + (!(watch('eventcampign_type') === 'active' || watch('eventcampign_type') === 'inactive') ? 'd-none' : '')}>
                      <label htmlFor="startDate" className="form-label fw-bold">Start date</label>
                      <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={moment().toDate()}
                        disabled={modalData?.item?.isactive}
                        render={(props) => (
                          <KeyboardDatePicker
                            format="DD-MM-YYYY"
                            margin="normal"
                            value={props.value}
                            onChange={(date) => {
                              props.onChange(date);
                              if (moment(getValues('endDate')).isBefore(moment(date?.toDate()))) {
                                setValue('endDate', date);
                              }
                            }}
                            disabled={modalData?.item?.isactive}
                            KeyboardButtonProps={{
                              'aria-label': 'change startDate date',
                            }}
                          />
                        )}
                      ></Controller>
                      {
                        (errors && errors.startDate && errors.startDate.type === "required") &&
                        <div className="is-invalid">Start date is required</div>
                      }
                    </div>
                    <div className={"col-3 " + (!(watch('eventcampign_type') === 'active' || watch('eventcampign_type') === 'inactive') ? 'd-none' : '')}>
                      <label htmlFor="endDate" className="form-label fw-bold">End date</label>
                      <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={moment().toDate()}
                        disabled={modalData?.item?.isactive}
                        render={(props) => (
                          <KeyboardDatePicker
                            format="DD-MM-YYYY"
                            margin="normal"
                            value={props.value}
                            onChange={(date) => props.onChange(date)}
                            disabled={modalData?.item?.isactive}
                            minDate={moment(watch('startDate')).toDate()}
                            KeyboardButtonProps={{
                              'aria-label': 'change endDate date',
                            }}
                          />
                        )}
                      ></Controller>
                      {
                        (errors && errors.endDate && errors.endDate.type === "required") &&
                        <div className="is-invalid">End date is required</div>
                      }
                    </div>
                  </>
                  <div className={"col-6 " + (watch('eventcampign_type') !== 'insurance' ? 'd-none' : '')}>
                    <label htmlFor="category" className="form-label fw-bold">Insurance</label>
                    <select disabled={modalData?.item?.isactive} className="form-select" aria-label="Select Insurance" id="insurance" name="insurance" ref={register()}>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                      <option value="all">All Patient</option>
                    </select>
                  </div>
                </div>
              }

              {
                categoryWatch === 'signinpatient' &&
                <div className={"row mb-4 "} >
                  <div className="col-12">
                    <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Target patients</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="allpatient" value="0,0" ref={register} />
                        <label className="form-check-label" htmlFor="allpatient">All patients</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month6-12" value="6,12" ref={register} />
                        <label className="form-check-label" htmlFor="month6-12">12 Months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-6" value="0,6" ref={register} />
                        <label className="form-check-label" htmlFor="month0-6">6 Months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-3" value="0,3" ref={register} />
                        <label className="form-check-label" htmlFor="month0-3">3 Months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-1" value="0,1" ref={register} />
                        <label className="form-check-label" htmlFor="month0-1">1 Months</label>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className={"row mb-4 " + (((categoryWatch === 'birthday' || categoryWatch === 'signinpatient' || categoryWatch === 'event') ? 'd-none' : ''))}>
                <div className="col-6">
                  <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Reoccurence limit</label>
                  <input disabled={modalData?.item?.isactive} type="number" className="form-control" aria-label="input hratings" min="1" name="reoccurencelimit" ref={register({ min: 1 })} placeholder="Please enter reoccurence limit"></input>
                  {
                    (errors && errors.reoccurencelimit && errors.reoccurencelimit.type === 'min') &&
                    <div className="is-invalid">{'Minimum should be 1'}</div>
                  }
                </div>
              </div>
              <div className={"row mb-4 d-none " + (((categoryWatch === 'birthday') ? 'd-none' : ''))}>
                <div className="col-6">
                  <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Health ratings</label>
                  <input disabled={modalData?.item?.isactive} className="form-control" aria-label="input hratings" name="healthrating" ref={register} placeholder="Please enter health ratings"></input>
                </div>
                <div className="col-6">
                  <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Financial ratings</label>
                  <input disabled={modalData?.item?.isactive} className="form-control" aria-label="input fratings" name="financialrating" ref={register} placeholder="Please enter financial ratings"></input>
                </div>
              </div>
              <div className={"row mb-4 " + (((categoryWatch === 'birthday' || categoryWatch === 'event' || categoryWatch === 'signinpatient') ? 'd-none' : ''))}>
                <div className="col-12">
                  <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Re-target</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="retarget" id="weekly" value="weekly" ref={register({ required: true })} />
                      <label className="form-check-label" htmlFor="weekly">Weekly</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="retarget" id="monthly" value="monthly" ref={register({ required: true })} />
                      <label className="form-check-label" htmlFor="monthly">Monthly</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="retarget" id="quaterly" value="quaterly" ref={register({ required: true })} />
                      <label className="form-check-label" htmlFor="quaterly">Quarterly</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="retarget" id="sixmonth" value="sixmonth" ref={register({ required: true })} />
                      <label className="form-check-label" htmlFor="everysix">Six months</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="retarget" id="yearly" value="yearly" ref={register({ required: true })} />
                      <label className="form-check-label" htmlFor="yearly">Yearly</label>
                    </div>
                  </div>
                  {
                    (errors && errors.retarget && errors.retarget.type === 'required') &&
                    <div className="is-invalid">{'Minimum should be 1'}</div>
                  }
                  <small className="text-muted mt-2">This reminder will sent to patient accordingly last appointment with clinic</small>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <input disabled={modalData?.item?.isactive} type="checkbox" className="form-check-input me-2" id="activeCampaign" name="activeCampaign" ref={register} />
                  <label className="form-check-label" htmlFor="exampleCheck1">Activate this campaign (You can do this later on campaign page)</label>
                  <div className="text-muted d-none">Note: active campaign fire automatic email, text & app notifications. You can disable/enable this on campaign page.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-border" data-bs-dismiss="modal" onClick={() => hideModal()}>Cancel</button>
            {
              !modalData?.item?.isactive &&
              <>
                <button type="submit" className="btn btn-primary">
                  {
                    isLoading &&
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                  }
                  {
                    modalData && modalData.item && 'Save'
                  }
                  {
                    (!modalData || (modalData && !modalData.item)) && 'Submit'
                  }
                </button>
              </>
            }
          </div>
        </div>
      </form>
    </Modal >
  )
});