import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useForm, Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import RichTextEditor from 'react-rte';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { createTemplate, updateTemplate, uploadPhotos } from '../../services/messageCenter';
import ClipboardIconPopup from '../../assets/images/clipboard-icon-popup.svg';

export const CreateTemplate = forwardRef((props: any, ref) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData]: any = useState();
  const { register, handleSubmit, errors, setError, getValues, setValue, control, watch } = useForm();
  const userDataResponse = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const clinic = userDataResponse.clinics[0];
  const showEmailTemplate = clinic.isenableemailtemplate || clinic.isenableemailtemplatemarketing;
  useImperativeHandle(ref, () => ({
    showModal: showModal,
    hideModal: hideModal
  }));
  const onSubmit = (data: any) => {
    setIsLoading(true);
    if (modalData && modalData.item) {
      updateTemplate({
        messagecentercampaigntemplate_id: modalData.item.messagecentercampaigntemplate_id,
        clinicid: userDataResponse.clinics[0].id,
        name: data.templatename,
        category: data.category,
        logourl: getValues('logoPreview'),
        bannerurl: getValues('bannerPreview'),
        subject: data.subject,
        body: data.body ? data.body.toString('html') : '',
        issocialmedialink: false,
        smstext: data.sms,
        appnotificationtext: data.appnotification,
        appointmentcta: data.appointmentcta,
        emailtemplate_id: showEmailTemplate ? data.emailTemplate : undefined
      }).then((success) => {
        setIsLoading(false);
        if (success && success.error === false) {
          toast.success('Updated successfully.');
          // modalData.getTemplateList();
          props.fetchTemplate();
          hideModal();
          return;
        }
        toast.warn('Something went wrong.');
      }).catch((err) => {
        setIsLoading(false);
        hideModal();
        // toast.error('Something went wrong.');
      });
    } else {
      createTemplate({
        clinicid: userDataResponse.clinics[0].id,
        name: data.templatename,
        category: data.category,
        logourl: getValues('logoPreview'),
        bannerurl: getValues('bannerPreview'),
        subject: data.subject,
        body: data.body ? data.body.toString('html') : '',
        issocialmedialink: false,
        smstext: data.sms,
        appnotificationtext: data.appnotification,
        appointmentcta: data.appointmentcta,
        emailtemplate_id: showEmailTemplate ? data.emailTemplate : undefined
      }).then((success) => {
        setIsLoading(false);
        if (success && success.error === false) {
          toast.success('Addedd successfully.');
          // modalData.getTemplateList();
          props.fetchTemplate();
          hideModal();
          return;
        }
        toast.warn('Something went wrong.');
      }).catch((err) => {
        setIsLoading(false);
        toast.error('Something went wrong.');
      })
    }
  };
  const showModal = (item: any = null) => {
    setShow(true);
    setModalData(item);
  };
  const hideModal = () => { setShow(false) };
  const onLogoChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      setError('logo', { type: 'filerequired', message: 'Logo is required.' });
      setValue('logo', null);
    } else {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        if (file.size > 512000) {
          setError('logo', {
            type: 'logosize', message: 'Maximum file size should be 512kb'
          });
          setValue('logo', null);
        } else {
          const files = event.target.files;
          uploadPhotos({ upload: event.target.files[0] }).then((success) => {
            if (success && success.error === false) {
              setValue('logoPreview', success.data);
              setValue('logo', files);
              setError('logo', {});
            }
          }).catch((err) => {
            setError('logo', {
              type: 'logotype', message: 'Image upload failed.'
            });
            setValue('logoPreview', '');
            setValue('logo', null);
          });
        }
      } else {
        setError('logo', {
          type: 'logotype', message: 'Logo extension is not valid, allowed png,jpg,jpeg'
        });
        setValue('logo', null);
      }
    }
  };
  const onBannerChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      setError('banner', { type: 'filerequired', message: 'Logo is required.' });
      setValue('banner', null);
    } else {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        if (file.size > 512000) {
          setError('banner', {
            type: 'bannersize', message: 'Maximum file size should be 512kb'
          });
          setValue('banner', null);
        } else {
          const files = event.target.files;
          uploadPhotos({ upload: event.target.files[0] }).then((success) => {
            if (success && success.error === false) {
              setValue('bannerPreview', success.data);
              setValue('banner', files);
              setError('banner', {});
            }
          }).catch((err) => {
            setError('banner', {
              type: 'bannertype', message: 'Image upload failed.'
            });
            setValue('bannerPreview', '');
            setValue('banner', null);
          });
        }
      } else {
        setError('banner', {
          type: 'bannertype', message: 'Banner extension is not valid, allowed png,jpg,jpeg'
        });
        setValue('banner', null);
      }
    }
  }
  useEffect(() => {
    if (modalData && modalData.item) {
      if (showEmailTemplate) {
        setValue('subject', clinic.name);
        setValue('body', RichTextEditor.createValueFromString(clinic.name, 'html'));
      } else {
        setValue('body', RichTextEditor.createValueFromString(modalData.item.body, 'html'));
        setValue('subject', modalData.item.subject);
      }
      setValue('templatename', modalData.item.name);
      setValue('category', modalData.item.category);
      setValue('sms', modalData.item.smstext);
      setValue('appnotification', modalData.item.appnotificationtext);
      setValue('appointmentcta', modalData.item.appointmentcta ? modalData.item.appointmentcta : false);
      setValue('bannerPreview', modalData.item.bannerurl);
      setValue('logoPreview', modalData.item.logourl);
    }
  }, [modalData]);
  useEffect(() => {
    if (props.emailTemplateList && modalData && modalData.item === null && showEmailTemplate) {
      setValue("emailTemplate", "");
      if (showEmailTemplate) {
        setValue('subject', clinic.name);
        setValue('body', RichTextEditor.createValueFromString(clinic.name, 'html'));
      }
    }
    if (props.emailTemplateList && modalData && modalData.item && showEmailTemplate) setValue('emailTemplate', modalData.item.emailtemplate_id);
  }, [props.emailTemplateList, modalData]);
  watch('logo');
  watch('banner');
  watch('bannerPreview');
  watch('logoPreview');
  return (
    <Modal className="camp-popups-sec" show={show} onHide={hideModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal d-block  position-relative">
          <div className="modal-header">
            <div>
              <h5 className="modal-title mb-0">Create templates</h5>
            </div>
          </div>
          <div className="modal-body">
            <div className="input-box">
              <div className="row mb-4">
                <div className="col-6">
                  <label htmlFor="templatename" className="form-label fw-bold">Template name</label>
                  <input type="text" className="form-control" id="templatename" placeholder="My template" name="templatename" ref={register({
                    required: true
                  })} />
                  {
                    (errors && errors.templatename && errors.templatename.type === "required") &&
                    <div className="is-invalid">Template Name is required</div>
                  }
                </div>
                <div className="col-6">
                  <label htmlFor="category" className="form-label fw-bold">Category</label>
                  <select className="form-select" aria-label="category select" id="category" name="category" ref={register({
                    required: true
                  })}>
                    <option value="recall">Recall</option>
                    <option value="event">Event</option>
                    <option value="birthday">Birthday</option>
                    <option value="recallmanual">Manual recall</option>
                    <option value="signinpatient">Signin patient</option>
                    <option value="signinpatientmanual">Signin patient manual</option>
                    <option value="importedpatientmanual">Imported customers</option>
                  </select>
                  {
                    (errors && errors.category && errors.category.type === "required") &&
                    <div className="is-invalid">Please select category.</div>
                  }
                </div>
              </div>

            </div>
            <div className={"mb-3 " + (!showEmailTemplate ? "d-none" : "")}>
              <label className="mb-1">Email template</label>
              <select className="form-control" name="emailTemplate" placeholder="Email template" ref={register({ required: showEmailTemplate ? true : false })}>
                <option value="">Select email template</option>
                {
                  props.emailTemplateList.map((item: any, index: number) => {
                    return (
                      <option key={item.messagecenteremailtemplate_id + "emailtemplate"}
                        value={item.messagecenteremailtemplate_id}>{item.templatename}</option>
                    )
                  })
                }
              </select>
              {
                errors && errors.emailTemplate && errors.emailTemplate.type === "required" &&
                <div className="is-invalid">Email template is Required</div>
              }
            </div>
            <div className="short-codes-box mb-3">
              <div className="mb-3"><span className="fw-bold">Short codes </span><small className="text-muted">(click icon to copy code.)</small></div>
              <div className="d-flex short-code-btns flex-wrap">
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip">
                      {"Use {patientname} in body to attach patient name."}
                    </Tooltip>
                  }
                >
                  <CopyToClipboard text={'{patientname}'} onCopy={() => toast.success('patient name copied!')}>
                    <button className="btn p-0" type="button">Patient name<img src={ClipboardIconPopup} className="ms-2" /></button>
                  </CopyToClipboard>

                </OverlayTrigger>

                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip">
                      {"Use {clinicname} in body to attach provider name."}
                    </Tooltip>
                  }
                >
                  <CopyToClipboard text={'{clinicname}'} onCopy={() => toast.success('Clinic name copied!')}>
                    <button className="btn p-0" type="button">Clinic name<img src={ClipboardIconPopup} className="ms-2" /></button>
                  </CopyToClipboard>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip">
                      {"Use {clinicsmsnumber} in body to attach speciality."}
                    </Tooltip>
                  }
                >
                  <CopyToClipboard text={'{clinicsmsnumber}'} onCopy={() => toast.success('ClinicSMSNumber is copied!')}>
                    <button className="btn p-0" type="button">ClinicSMSNumber<img src={ClipboardIconPopup} className="ms-2" /></button>
                  </CopyToClipboard>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip">
                      {"Use {clinicadminemail} in body to attach speciality."}
                    </Tooltip>
                  }
                >
                  <CopyToClipboard text={'{clinicadminemail}'} onCopy={() => toast.success('ClinicAdminEmail is copied!')}>
                    <button className="btn p-0" type="button">ClinicAdminEmail<img src={ClipboardIconPopup} className="ms-2" /></button>
                  </CopyToClipboard>
                </OverlayTrigger>
                {
                  watch('category') !== 'signinpatient' && (
                    <>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip">
                            {"Use {appointmentstarttime} in body to attach provider name."}
                          </Tooltip>
                        }
                      >
                        <CopyToClipboard text={'{appointmentstarttime}'} onCopy={() => toast.success('Appointment start time copied!')}>
                          <button className="btn p-0" type="button">Appointment start time<img src={ClipboardIconPopup} className="ms-2" /></button>
                        </CopyToClipboard>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip">
                            {"Use {appointmentdate} in body to attach provider name."}
                          </Tooltip>
                        }
                      >
                        <CopyToClipboard text={'{appointmentdate}'} onCopy={() => toast.success('Appointment date copied!')}>
                          <button className="btn p-0" type="button">Appointment date<img src={ClipboardIconPopup} className="ms-2" /></button>
                        </CopyToClipboard>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip">
                            {"Use {speciality} in body to attach speciality."}
                          </Tooltip>
                        }
                      >
                        <CopyToClipboard text={'{speciality}'} onCopy={() => toast.success('Speciality is copied!')}>
                          <button className="btn p-0" type="button">Speciality<img src={ClipboardIconPopup} className="ms-2" /></button>
                        </CopyToClipboard>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip">
                            {"Use {appointment} in body to attach appointment detail."}
                          </Tooltip>
                        }
                      >
                        <CopyToClipboard text={'{appointment}'} onCopy={() => toast.success('appointment copied!')}>
                          <button className="btn p-0" type="button">Apppointment<img src={ClipboardIconPopup} className="ms-2" /></button>
                        </CopyToClipboard>

                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip">
                            {"Use {providername} in body to attach provider name."}
                          </Tooltip>
                        }
                      >
                        <CopyToClipboard text={'{providername}'} onCopy={() => toast.success('provider name copied!')}>
                          <button className="btn p-0" type="button">Provider name<img src={ClipboardIconPopup} className="ms-2" /></button>
                        </CopyToClipboard>

                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip">
                            {"Use {amount} in body to attach provider name."}
                          </Tooltip>
                        }
                      >
                        <CopyToClipboard text={'{amount}'} onCopy={() => toast.success('Amount copied!')}>
                          <button className="btn p-0" type="button">Amount<img src={ClipboardIconPopup} className="ms-2" /></button>
                        </CopyToClipboard>
                      </OverlayTrigger>

                    </>
                  )
                }
                {
                  (watch('category') === 'signinpatient' || watch('category') === 'signinpatientmanual') &&
                  <>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip">
                          {"Use {patientsigninemail} in body to attach speciality."}
                        </Tooltip>
                      }
                    >
                      <CopyToClipboard text={'{patientsigninemail}'} onCopy={() => toast.success('Patient Signin Email is copied!')}>
                        <button className="btn p-0" type="button">PatientSigninEmail<img src={ClipboardIconPopup} className="ms-2" /></button>
                      </CopyToClipboard>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip">
                          {"Use {patientsigninmobile} in body to attach speciality."}
                        </Tooltip>
                      }
                    >
                      <CopyToClipboard text={'{patientsigninmobile}'} onCopy={() => toast.success('Patient Signin Mobile is copied!')}>
                        <button className="btn p-0" type="button">PatientSigninMobile<img src={ClipboardIconPopup} className="ms-2" /></button>
                      </CopyToClipboard>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip">
                          {"Use {patientsigninpassword} in body to attach speciality."}
                        </Tooltip>
                      }
                    >
                      <CopyToClipboard text={'{patientsigninpassword}'} onCopy={() => toast.success('Patient Signin Password is copied!')}>
                        <button className="btn p-0" type="button">PatientSigninPassword<img src={ClipboardIconPopup} className="ms-2" /></button>
                      </CopyToClipboard>
                    </OverlayTrigger>
                  </>
                }
              </div>
            </div>
            <div className="tab-box">
              {
                showEmailTemplate &&
                <div className={"my-3 "}>
                  <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Subject line</label>
                  <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter email subject line" name="subject" ref={register({
                    required: true
                  })} />
                  {
                    errors.subject && errors.subject.type === "required" &&
                    <div className="is-invalid">Subject is required</div>
                  }
                </div>
              }
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {
                    !showEmailTemplate &&
                    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Email</button>
                  }
                  <button className={"nav-link " + (showEmailTemplate ? "active" : "")} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Text SMS</button>
                  <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">App notification</button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className={"tab-pane fade " + (!showEmailTemplate ? "show active" : "")} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div className="row">
                    <div className="col-6">
                      <div className="upload-box">
                        <label className="fw-bold form-label d-block" htmlFor="exampleFormControlFile1">Upload logo</label>
                        <div className="">
                          <input ref={register} className="btn btn-upload" type="file" name="logo" onChange={onLogoChange} />


                          <small className="text-muted mt-1 d-block">Max file size should be 500kb</small>
                          {
                            errors && errors.logo && errors.logo.type === "logosize" &&
                            <div className="is-invalid">{errors.logo.message}</div>
                          }
                          {
                            errors && errors.logo && errors.logo.type === "logotype" &&
                            <div className="is-invalid">{errors.logo.message}</div>
                          }
                          {
                            errors && errors.logo && errors.logo.type === "filerequired" &&
                            <div className="is-invalid">{errors.logo.message}</div>
                          }

                          {/* <div className="img-box"> */}
                          <input type="hidden" name="logoPreview" ref={register}></input>
                          {
                            (getValues('logo') && getValues('logo')[0] && getValues('logo')[0].name) ?
                              <><div className="img-box"><img id="logo" src={URL.createObjectURL(getValues('logo')[0])} /></div></> :
                              (getValues('logoPreview') && getValues('logoPreview') !== '') &&
                              <>
                                {
                                  (getValues('logoPreview')) &&
                                  <><div className="img-box"><img id="logo" src={getValues('logoPreview')} /></div></>
                                }
                              </>
                          }
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="upload-box">
                        <label className="fw-bold form-label d-block" htmlFor="exampleFormControlFile1">Banner image</label>
                        <div className="">
                          <input ref={register} className="btn btn-upload" type="file" name="banner" onChange={onBannerChange} />
                        </div>
                        <small className="text-muted mt-1 d-block">Max file size should be 500kb</small>
                        {
                          errors && errors.banner && errors.banner.type === "bannersize" &&
                          <div className="is-invalid">{errors.banner.message}</div>
                        }
                        {
                          errors && errors.banner && errors.banner.type === "bannertype" &&
                          <div className="is-invalid">{errors.banner.message}</div>
                        }
                        {
                          errors && errors.banner && errors.banner.type === "filerequired" &&
                          <div className="is-invalid">{errors.logo.message}</div>
                        }
                        {/* <div className="img-box"> */}
                        <input type="hidden" name="bannerPreview" ref={register}></input>
                        {
                          (getValues('banner') && getValues('banner')[0] && getValues('banner')[0].name) ?
                            <><div className="img-box"><img id="banner" src={URL.createObjectURL(getValues('banner')[0])} /></div></> :
                            (getValues('bannerPreview') && getValues('bannerPreview') !== '') &&
                            <>
                              {
                                (getValues('bannerPreview')) &&
                                <><div className="img-box"><img id="banner" src={getValues('bannerPreview')} /></div></>
                              }
                            </>
                        }
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                  {
                    !showEmailTemplate &&
                    <div className="my-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Subject line</label>
                      <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter email subject line" name="subject" ref={register({
                        required: showEmailTemplate ? true : false
                      })} />
                      {
                        errors.subject && errors.subject.type === "required" &&
                        <div className="is-invalid">subject is required</div>
                      }
                    </div>
                  }
                  <div className="my-3">
                    <label className="form-label fw-bold">Content</label>
                    <div className="editor-box form-control">
                      {/* <div className="editor-strip"><img src="./images/editor-strip.svg" /></div> */}
                      <Controller
                        name="body"
                        control={control}
                        render={(props) => {
                          return (
                            <RichTextEditor value={props.value ? props.value : RichTextEditor.createValueFromString('', 'html')}
                              onChange={(value) => props.onChange(value)}
                            ></RichTextEditor>
                          )
                        }}
                      ></Controller>
                      {/* <textarea id="PracticeDescription" rows={3}>When you need family dental care, you want a dentist that provides you with comfortable and convenient services in a relaxing atmosphere.</textarea> */}
                    </div>

                  </div>
                  <div className="my-3 d-none">
                    <label className="form-label fw-bold">Add CTA</label>
                    <div className="d-block mb-1">
                      <input type="checkbox" className="form-check-input me-2" id="appointmentcta" name="appointmentcta" ref={register} />
                      <label className="form-check-label fw-medium" htmlFor="appointmentcta">Appointment confirmation</label>
                    </div>
                    <small className="text-muted">This CTA visible only for schedule appointment template </small>
                  </div>
                  <div className="my-3 d-none">
                    <div className="d-flex form-check form-switch justify-content-between border p-3 rounded-10">
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Social media links</label>
                      <input className="form-check-input m-0 align-self-center" type="checkbox" id="flexSwitchCheckDefault" />
                    </div>
                  </div>
                </div>
                <div className={"tab-pane fade " + (showEmailTemplate ? "active show" : "")} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div>
                    <label htmlFor="sms" className="form-label fw-bold">Text message content</label>
                    <textarea className="form-control mb-1" id="sms" rows={3} name="sms" ref={register({
                      required: true
                    })}></textarea>
                    <small className="text-muted">The character limit for a single SMS message is 160 characters</small>
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div>
                    <label htmlFor="appnotification" className="form-label fw-bold">App notification content</label>
                    <textarea className="form-control mb-1" id="appnotification" rows={3} name="appnotification" ref={register({
                      required: true
                    })}></textarea>
                    <small className="text-muted">The character limit for a single app notification is 250 characters</small>
                  </div>
                </div>
              </div>
            </div>
            {
              (errors && errors.sms && errors.sms.type === "required") &&
              <div className="is-invalid">SMS Text is required.</div>
            }
            {
              (errors && errors.appnotification && errors.appnotification.type === "required") &&
              <div className="is-invalid">App Notification is required.</div>
            }
          </div>
          <div className="modal-footer">
            <button type="button" disabled={isLoading} className="btn btn-border" data-bs-dismiss="modal" onClick={() => hideModal()}>Cancel</button>
            <button disabled={isLoading} type="submit" className="btn btn-primary">
              {
                isLoading &&
                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
              }
              {
                modalData && modalData.item && 'Save'
              }
              {
                (!modalData || (modalData && !modalData.item)) && 'Submit'
              }
            </button>
          </div>
        </div>
      </form>
    </Modal >
  );
});