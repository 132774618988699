import React, { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { CreateCampaign } from './CreateCampaign';
import { CreateTemplate } from './CreateTemplate';
export const CampaignHeader = () => {
  const location = useLocation();
  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between">
            <div>
              <div className="nav-title-txt">Marketing Campaigns</div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <NavLink className="nav-link" exact={true} to={"/message-center/campaigns/campaigns"}>All Campaigns</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" exact={true} to={"/message-center/campaigns/templates"}>Templates</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" exact={true} to={"/message-center/campaigns/patients"}>Patients</NavLink>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}