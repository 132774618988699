import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { createManualCampaign, getAppointmentCategoryList, getDependentsBasedOnCategoryAndProceedures, getImportedManualPatientList, getManualPatientList, getProcedureCodeList, templateList, updateManualCampaign } from '../../services/messageCenter';
import WarningIcon from '../../assets/images/warning-icon.svg';
export const CreateManualCampaign = forwardRef((props: any, ref) => {
  const [show, setShow] = useState(false);
  const [modalData, setModalData]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates]: any = useState([]);
  const userDataResponse = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const [appointmentCategories, setAppointmentCategories]: any = useState([]);
  const [procedureCodes, setProcedureCodes]: any = useState([]);
  const [appointmentList, setAppointemntList]: any = useState([]);
  const [appointmentCategoryErr, setAppointmentCategoryError] = useState("");
  const { register, handleSubmit, errors, trigger, getValues, setValue, watch } = useForm({
    defaultValues: {
      category: 'recallmanual',
      campaignName: '',
      template: '',
      email: '',
      text: '',
      overduepatient: '0,0',
      customerType: 'recall',
      activeCampaign: false,
      appointmentcategory: '',
      procedurecodes: '',
      appointments: '',
      appointmentcategorytype: 'custom',
      procedurecodestype: 'custom'
    }
  });
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    showModal: showModal,
    hideModal: hideModal
  }));
  const showModal = (item: any = null) => {
    setShow(true);
    setModalData(item);
  };
  const hideModal = () => { setShow(false) };
  const onSubmit = (data: any) => {
    console.log(data);
    if (data?.appointmentcategory?.toString().length === 0) {
      setAppointmentCategoryError("Required");
      return;
    }
    let templateTypes = '';
    if (data.email) {
      templateTypes = templateTypes + 'email,';
    }
    if (data.text) {
      templateTypes = templateTypes + 'text';
    }
    console.log(data);
    const request = {
      clinicid: userDataResponse.clinics[0].id,
      title: data.campaignName,
      messagecentercampaigntemplate_id: data.template,
      categorytype: data.category,
      templatetypes: templateTypes,
      overduepatients: data.overduepatient,
      isactive: data.activeCampaign,
    }
    if (categoryWatch === 'signinpatientmanual') {
      Object.assign(request, {
        appointment_ids: "",
        dependant_login_ids: data.appointments.toString(),
      });
    } else if (categoryWatch === 'importedpatientmanual') {
      Object.assign(request, {
        appointment_ids: "",
        dependant_login_ids: data.appointments.toString(),
      });
    } else {
      Object.assign(request, {
        dependant_login_ids: "",
        appointment_ids: data.appointments.toString(),
      })
    }
    if (categoryWatch === 'recallmanual') {
      let appointmentCategoryTypeWatch = watch('appointmentcategorytype');
      let procedureCodesTypeWatch = watch('procedurecodestype');
      let procedure_categoryTemp: any = '', procedure_codeTemp: any = '';

      // appointment categories
      if (appointmentCategoryTypeWatch === 'all') {
        procedure_categoryTemp = 'all';
      } else if (appointmentCategoryTypeWatch === 'none') {
        procedure_categoryTemp = 'none';
      } else {
        procedure_categoryTemp = data.appointmentcategory.toString();
      }

      // Procedure code
      if (procedureCodesTypeWatch === 'all') {
        procedure_codeTemp = 'all';
      } else if (procedureCodesTypeWatch === 'none') {
        procedure_codeTemp = 'none';
      } else {
        procedure_codeTemp = data.procedurecodes.toString();
      }

      Object.assign(request, {
        procedure_category: procedure_categoryTemp,
        procedure_code: procedure_codeTemp
      });
    }
    setIsLoading(true);
    if (modalData && modalData.item) {
      Object.assign(request, { messagecentermanualcampaign_id: modalData.item.messagecentermanualcampaign_id });
      updateManualCampaign(request).then((success: any) => {
        setIsLoading(false);
        if (success && success.error === false) {
          toast.success('Campaign update successfully.');
          hideModal();
          props.fetchList();
        }
      }).catch((err) => {
        setIsLoading(false);
        toast.error('Something went wrong');
      })
      return;
    }
    createManualCampaign(request).then((success) => {
      setIsLoading(false);
      if (success && success.error === false) {
        toast.success('Campaign create successfully.');
        hideModal();
        props.fetchList();
      }
    }).catch((err) => {
      setIsLoading(false);
      toast.error('Something went wrong');
    })
  };
  const categoryWatch = watch('category');

  const fetchAppointmentCategoryListOnEdit = () => {
    dispatch(fetchLoadingPending());
    getAppointmentCategoryList({
      clinicid: userDataResponse.clinics[0].id,
    }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false) {
        setAppointmentCategories(success.data);
        if (modalData && modalData.item) {
          let procedure_code_categoryTemp: any = '';
          // Appointment category
          if (modalData.item.procedure_category === 'all') {
            setValue('appointmentcategorytype', 'all');
            let values: any = [];
            for (let i = 0; i < success.data.length; i++) {
              values.push(success.data[i].procedure_code_category)
            }
            setValue('appointmentcategory', values);
            fetchProcedureCodeOnEdit('all', []);
          } else {
            setValue('appointmentcategorytype', 'custom');
            if (modalData.item && modalData.item.procedure_category) {
              procedure_code_categoryTemp = modalData.item.procedure_category.split(",");
            } else {
              procedure_code_categoryTemp = [];
            }
            setValue('appointmentcategory', procedure_code_categoryTemp);
            fetchProcedureCodeOnEdit('custom', procedure_code_categoryTemp);
          }
        }
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
    })
  }

  const fetchProcedureCode = () => {
    dispatch(fetchLoadingPending());
    getProcedureCodeList({
      clinicid: userDataResponse.clinics[0].id,
      category: watch('appointmentcategory'),
      type: watch('appointmentcategorytype')
    }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false) {
        if (success.data && success.data[0]) {
          setProcedureCodes(success.data[0]);
          if (watch('procedurecodestype') === 'all') {
            let values: any = [];
            for (let i = 0; i < success.data[0].length; i++) {
              values.push(success.data[0][i].procedure_code_sikkaId)
            }
            setValue('procedurecodes', values);
            // fetch dependants on change
            fetchDependants();
          }
        }
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
    })
  }
  const fetchProcedureCodeOnEdit = (type: any, categories?: any) => {
    dispatch(fetchLoadingPending());
    getProcedureCodeList({
      clinicid: userDataResponse.clinics[0].id,
      category: categories ? categories : watch('appointmentcategory'),
      type: type
    }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false) {
        if (success.data && success.data[0]) {
          setProcedureCodes(success.data[0]);
          if (modalData && modalData.item) {
            let procedure_codeTemp: any = '';
            // procedure code
            if (modalData.item.procedure_code === 'all') {
              setValue('procedurecodestype', 'all');
              let values: any = [];
              for (let i = 0; i < success.data[0].length; i++) {
                values.push(success.data[0][i].procedure_code_sikkaId)
              }
              setValue('procedurecodes', values);
            } else {
              setValue('procedurecodestype', 'custom');
              procedure_codeTemp = modalData.item.procedure_code.split(",");
              setValue('procedurecodes', procedure_codeTemp);
            }
            fetchDependants();
          }
        }
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
    })
  }

  const onChnageAppointmentCategory = () => {
    if (watch('appointmentcategory') && watch('appointmentcategory').length > 0) {
      fetchProcedureCode();
    }
  }

  useEffect(() => {
    if (modalData && modalData.item && categoryWatch && modalData.item.categorytype !== categoryWatch) { return; }
    if (categoryWatch && show) {
      dispatch(fetchLoadingPending());
      templateList({
        clinicid: userDataResponse.clinics[0].id,
        category: categoryWatch
      }).then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.error === false) {
          setTemplates(success.data);
        }
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      })
      if (categoryWatch === 'recallmanual' && modalData === null) {
        setValue('procedurecodes', []);
        setValue('procedurecodestype', 'custom');
        setProcedureCodes([]);
        dispatch(fetchLoadingPending());
        getAppointmentCategoryList({
          clinicid: userDataResponse.clinics[0].id,
        }).then((success) => {
          dispatch(fetchLoadingSuccess(false));
          if (success && success.error === false) {
            setAppointmentCategories(success.data);
          }
        }).catch((err) => {
          dispatch(fetchLoadingSuccess(false));
        })
      }
    }
  }, [categoryWatch, show]);
  useEffect(() => {
    if (modalData && modalData.item) {
      setValue('campaignName', modalData.item.title);
      setValue('category', modalData.item.categorytype);
      if (modalData.item.categorytype === 'recallmanual') {
        fetchAppointmentCategoryListOnEdit()
      }
      setValue('template', modalData.item.messsagecentercampaigntemplate_id);
      setValue('email', modalData.item.templatetypes.includes("email"));
      setValue('text', modalData.item.templatetypes.includes("text"));
      console.log(modalData.item.overduepatients);
      setValue('overduepatient', modalData.item.overduepatients);
      setValue('activeCampaign', modalData.item.isactive);
    }
  }, [modalData]);

  const fetchDependants = () => {
    if (getValues('procedurecodes') && getValues('procedurecodes').length > 0 && getValues('appointmentcategory') && getValues('appointmentcategory').length > 0) {
      dispatch(fetchLoadingPending());
      let type = "";
      if (getValues('overduepatient') === "0,6") {
        type = "0To6";
      } else if (getValues('overduepatient') === "6,12") {
        type = "7To12";
      } else if (getValues('overduepatient') === "12,18") {
        type = "13To18";
      } else if (getValues('overduepatient') === "18,24") {
        type = "19To24";
      }
      let category = "";
      if (getValues('appointmentcategorytype') === "custom") {
        category = getValues('appointmentcategory');
      }
      let code = "";
      if (getValues('procedurecodestype') === "custom") {
        code = getValues('procedurecodes');
      }
      getDependentsBasedOnCategoryAndProceedures(userDataResponse.clinics[0].id, type, code, category).then((success) => {
        dispatch(fetchLoadingSuccess(false));
        console.log(success);
        if (success && success.status.error === false) {
          console.log(success);
          if (success.appointments) {
            setAppointemntList(success.appointments)
            if (modalData && modalData.item) {
              if (modalData.item?.categorytype === 'signinpatientmanual') {
                setValue("appointments", modalData.item.dependant_login_ids.split(","))
              } else {
                setValue("appointments", modalData.item.appointment_ids.split(","))
              }
            }
          }
        }
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      })
    }
  }

  const handleOnChnageAll = () => {
    if (watch('appointmentcategorytype') === 'all') {
      let values: any = [];
      for (let i = 0; i < appointmentCategories.length; i++) {
        values.push(appointmentCategories[i].procedure_code_category)
      }
      setValue('appointmentcategory', values);
      trigger("appointmentcategory");
      fetchProcedureCode();
    }
  }

  const handleOnChangeCustom = () => {
    setValue('appointmentcategory', []);
  }

  useEffect(() => {
    let type = watch('procedurecodestype');
    if (type === 'all' && procedureCodes.length > 0) {
      let values: any = [];
      for (let i = 0; i < procedureCodes.length; i++) {
        values.push(procedureCodes[i].procedure_code_sikkaId)
      }
      setValue('procedurecodes', values);
    } else {
      setValue('procedurecodes', []);
    }
    fetchDependants();
  }, [watch('procedurecodestype')]);

  useEffect(() => {
    if (categoryWatch === 'signinpatientmanual') {
      getPatientManualList();
    } else if (categoryWatch === 'importedpatientmanual' && getValues('customerType')) {
      _getImportedManualPatientList();
    }
  }, [categoryWatch, watch('customerType')]);

  const getPatientManualList = async () => {
    try {
      const response = await getManualPatientList({
        clinicid: userDataResponse.clinics[0].id,
        target_patient: getValues('overduepatient')
      });
      setAppointemntList(response.data.map((item: any) => ({ ...item, AppointmentId: item.loginid, patientName: item.patientfname + " " + item.patientlname })))
      console.log("response", response);
      if (modalData?.item?.categorytype === 'signinpatientmanual' && modalData?.item && modalData?.item?.dependant_login_ids) {
        setValue("appointments", '')
      }
    } catch (err) {
      console.log(err);
      setAppointemntList([]);
    }
  }
  const _getImportedManualPatientList = async () => {
    try {
      const response = await getImportedManualPatientList({
        clinicid: userDataResponse.clinics[0].id,
        customertype: getValues('customerType')
      });
      setAppointemntList(response.data.map((item: any) => ({ ...item, AppointmentId: item.loginid, patientName: item.patientfname + " " + item.patientlname })))
      console.log("response", response);
      if (modalData?.item?.categorytype === 'importedpatientmanual' && modalData?.item && modalData?.item?.dependant_login_ids) {
        setValue("appointments", modalData.item.dependant_login_ids.split(","))
      }
    } catch (err) {
      console.log(err);
      setAppointemntList([]);
    }
  }

  return (
    <Modal className="camp-popups-sec" show={show} onHide={hideModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal d-block position-relative">
          <div className="modal-header">
            <div>
              <h5 className="modal-title mb-0">{modalData?.item?.isactive ? 'View campaign' : 'Create campaign'}</h5>
            </div>
          </div>
          <div className="modal-body">
            <div className={"input-box"}>
              <div className="row mb-4">
                <div className="col-6">
                  <label htmlFor="campaignName" className="form-label fw-bold">Title</label>
                  <input disabled={modalData?.item?.isactive} type="text" className="form-control" id="campaignName" name="campaignName" placeholder="Campaign title" ref={register({
                    required: true,
                  })} />
                  <small className="text-muted">ex. recall reminder.</small>
                  {
                    (errors && errors.campaignName && errors.campaignName.type === "required") &&
                    < div className="is-invalid">Campaign Name is required</div>
                  }
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label htmlFor="category" className="form-label fw-bold">Feature Category</label>
                  <select disabled={modalData?.item?.isactive} className="form-control" name="category" id="category" ref={register({ required: true })}>
                    <option value="recallmanual">Recall manual</option>
                    <option value="signinpatientmanual">Singin patient manual</option>
                    <option value="importedpatientmanual">Imported customers</option>
                  </select>
                  {/* <input className="form-control" name="category" id="category" ref={register({ required: true })} disabled/> */}
                  {/* <select className="form-select" aria-label="Select category" id="category" name="category" ref={register({ required: true })}>
                    <option value="recallmanual">Manual recall</option>
                  </select> */}
                  {
                    (errors && errors.category && errors.category.type === "required") &&
                    <div className="is-invalid">Category is required</div>
                  }
                </div>
                <div className="col-6">
                  <label htmlFor="template" className="form-label fw-bold">Template</label>
                  <select disabled={modalData?.item?.isactive} className="form-select" aria-label="Select template" name="template" defaultValue={templates.length > 0 ? templates[0].messagecentercampaigntemplate_id : ''} ref={register({ required: true })}>
                    <option value="" selected disabled>Select</option>
                    {
                      templates.map((item: any, index: number) => (
                        <option value={item.messagecentercampaigntemplate_id} key={index + item.messagecentercampaigntemplate_id}>{item.name}</option>
                      ))
                    }
                  </select>
                  {
                    (templates && templates.length === 0) &&
                    <label>No templates found, please add by visting <NavLink to="/message-center/campaigns/templates">Add Template</NavLink></label>
                  }
                  {
                    (errors && errors.template && errors.template.type === "required") &&
                    <div className="is-invalid">Template is required</div>
                  }
                  {/* <button type="button" className="btn text-primary float-end px-0">Create new template</button> */}
                </div>
              </div>
              {
                watch('category') === 'importedpatientmanual' &&
                <div className={"row mb-4 " + ((categoryWatch === 'birthday' ? 'd-none' : ''))} >
                  <div className="col-12">
                    <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Customer type</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="customerType" id="allpatient" value="recall" ref={register} />
                        <label className="form-check-label" htmlFor="allpatient">Recall</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="customerType" id="month0-6" value="marketing" ref={register} />
                        <label className="form-check-label" htmlFor="month0-6">Marketing</label>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {
                watch('category') === 'signinpatientmanual' &&
                <div className={"row mb-4 "} >
                 <div className="col-12">
                    <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Target patients</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="allpatient" value="0,0" ref={register} 
                        onChange={() => {
                          getPatientManualList();
                        }}/>
                        <label className="form-check-label" htmlFor="allpatient">All patients</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month6-12" value="6,12" ref={register} 
                        onChange={() => {
                          getPatientManualList();
                        }}/>
                        <label className="form-check-label" htmlFor="month6-12">12 Months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-6" value="0,6" ref={register} 
                        onChange={() => {
                          getPatientManualList();
                        }}/>
                        <label className="form-check-label" htmlFor="month0-6">6 Months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-3" value="0,3" ref={register} 
                        onChange={() => {
                          getPatientManualList();
                        }}/>
                        <label className="form-check-label" htmlFor="month0-3">3 Months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-1" value="0,1" ref={register} 
                        onChange={() => {
                          getPatientManualList();
                        }}/>
                        <label className="form-check-label" htmlFor="month0-1">1 Months</label>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {
                watch('category') !== 'signinpatientmanual' && watch('category') !== 'importedpatientmanual' &&
                <div className={"row mb-4 " + ((categoryWatch === 'birthday' ? 'd-none' : ''))} >
                  <div className="col-12">
                    <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Over due patients</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="allpatient" value="0,0" ref={register} onChange={() => {
                          fetchDependants()
                        }} />
                        <label className="form-check-label" htmlFor="allpatient">All patients</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month0-6" value="0,6" ref={register} onChange={() => {
                          fetchDependants()
                        }} />
                        <label className="form-check-label" htmlFor="month0-6">0-6 months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month6-12" value="6,12" ref={register} onChange={() => {
                          fetchDependants()
                        }} />
                        <label className="form-check-label" htmlFor="month6-12">7-12 months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month12-18" value="12,18" ref={register} onChange={() => {
                          fetchDependants()
                        }} />
                        <label className="form-check-label" htmlFor="month12-18">13-18 months</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="overduepatient" id="month18-24" value="18,24" ref={register} onChange={() => {
                          fetchDependants()
                        }} />
                        <label className="form-check-label" htmlFor="month12-18">19-24 months</label>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <>
                {
                  watch('category') !== 'signinpatientmanual' && watch('category') !== 'importedpatientmanual' &&
                  <div className="row mb-4">
                    <div className="col-6">
                      <label htmlFor="category" className="form-label fw-bold">Appointment Category</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="appointmentcategorytype" id="allcategory" value="all" ref={register} onChange={() => { handleOnChnageAll() }} />
                          <label className="form-check-label" htmlFor="allcategory">All</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input disabled={modalData?.item?.isactive} className="form-check-input" type="radio" name="appointmentcategorytype" id="customcategory" value="custom" ref={register} onChange={() => { handleOnChangeCustom() }} />
                          <label className="form-check-label" htmlFor="customcategory">Custom</label>
                        </div>
                      </div>
                      <select disabled={watch('appointmentcategorytype') === 'all' || modalData?.item?.isactive} multiple={true} className="form-control" aria-label="Select Appointment Category" id="appointmentcategory" name="appointmentcategory" ref={register} onChange={() => {
                        onChnageAppointmentCategory();
                      }}>
                        {
                          (appointmentCategories && appointmentCategories.length > 0) &&
                          appointmentCategories.map((category: any) => {
                            return (
                              <option key={category.procedure_code_category} value={category.procedure_code_category}>{category.procedure_code_category}</option>
                            )
                          })
                        }
                      </select>
                      {
                        (appointmentCategoryErr !== "") &&
                        <div className="is-invalid">{appointmentCategoryErr}</div>
                      }
                    </div>
                    <div className="col-6">
                      <label htmlFor="category" className="form-label fw-bold">Procedure Codes</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input disabled={watch('appointmentcategorytype') === 'none' || modalData?.item?.isactive} className="form-check-input" type="radio" name="procedurecodestype" id="allcodetype" value="all" ref={register} />
                          <label className="form-check-label" htmlFor="allcodetype">All</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input disabled={watch('appointmentcategorytype') === 'none' || modalData?.item?.isactive} className="form-check-input" type="radio" name="procedurecodestype" id="customcodetype" value="custom" ref={register} />
                          <label className="form-check-label" htmlFor="customcodetype">Custom</label>
                        </div>
                      </div>
                      <select disabled={watch('procedurecodestype') === 'all' || modalData?.item?.isactive} multiple={true} className="form-control" aria-label="Select Procedure Codes" id="procedurecodes" name="procedurecodes" ref={register({ required: true })}
                        onChange={() => {
                          // fetch dependants on change
                          fetchDependants();
                        }}>
                        {
                          (procedureCodes && procedureCodes.length > 0) &&
                          procedureCodes.map((code: any) => {
                            return (
                              <option key={code.procedure_code_sikkaId} value={code.procedure_code_sikkaId}>{code.procedure_code_sikkaId}</option>
                            )
                          })
                        }
                      </select>
                      {
                        (errors && errors.procedurecodes && errors.procedurecodes.type === "required") &&
                        <div className="is-invalid">Required</div>
                      }
                    </div>
                  </div>
                }
              </>

              <div className="row mb-4">
                <div className="col-12">
                  <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Dependants</label>
                  <div>
                    <select disabled={((watch('category') === 'recallmanual' && (watch('appointmentcategory').length == 0 || watch('procedurecodes').length === 0)) || modalData?.item?.isactive)} multiple={true} className="form-control" aria-label="Select Dependents" id="appointments" name="appointments" ref={register({ required: true })}>
                      {
                        (appointmentList && appointmentList.length > 0) &&
                        appointmentList.map((item: any) => {
                          return (
                            <option key={item.AppointmentId} value={item.AppointmentId}>{item.patientName}</option>
                          )
                        })
                      }
                    </select>
                    {
                      (errors && errors.appointments && errors.appointments.type === "required") &&
                      <div className="is-invalid">Required</div>
                    }
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Notification medium</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="checkbox" id="emailCheckbox" name="email" value="email" ref={register} />
                      <label className="form-check-label" htmlFor="emailCheckbox">Email</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input disabled={modalData?.item?.isactive} className="form-check-input" type="checkbox" id="textCheckbox" name="text" value="text" ref={register} />
                      <label className="form-check-label" htmlFor="textCheckbox">Text</label>
                    </div>
                  </div>
                  <div className="text-muted small mt-2 d-none"><img src={WarningIcon} className="me-2" />Disable is not configured, please configure email & text in selected template.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-border" data-bs-dismiss="modal" onClick={() => hideModal()}>Cancel</button>
            {
              !modalData?.item?.isactive &&
              <button type="submit" className="btn btn-primary">
                {
                  isLoading &&
                  <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                }
                {
                  modalData && modalData.item && 'Save'
                }
                {
                  (!modalData || (modalData && !modalData.item)) && 'Submit'
                }
              </button>
            }
          </div>
        </div>
      </form>
    </Modal >
  )
});