import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { CampaignHeader } from '../../components/messageCenter/CampaignHeader';
import { campaignList } from '../../services/messageCenter';
import { AllCampaign } from './AllCampaign';
import { Patients } from './CampaignPatients';
import { Templates } from './Templates';
export const Campaign = () => {
  const match = useRouteMatch();
  const [isAuthorize, setAuthorize] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const userDataResponse = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  useEffect(() => {
    dispatch(fetchLoadingPending());
    campaignList({ clinicid: userDataResponse.clinics[0].id }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success.error == false) {
        setAuthorize(true);
        setIsLoaded(true);
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
      setAuthorize(false);
      setIsLoaded(true);
    });
  }, []);
  return (
    <>
      <CampaignHeader></CampaignHeader>
      {
        isAuthorize && isLoaded &&
        <Switch>
          <Route path={match.url + '/patients'} component={Patients}></Route>
          <Route path={match.url + '/templates'} component={Templates}></Route>
          <Route path={match.url + '/campaigns'} component={AllCampaign}></Route>
          <Redirect to={match.url + '/campaigns'}></Redirect>
        </Switch>
      }
      {
        !isAuthorize && isLoaded &&
        <div className="overlay-center">
          <div className="vertical-center">

            <div className="card">
              <div className="card-body text-center p-5">
                <div className="mb-3">Please contact admin to access marketing. You haven't subscribed to marketing section on message center.</div>
              </div>
            </div>

          </div>
        </div>
      }
    </>
  );
}