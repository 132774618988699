import React from 'react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { ViewState, GroupingState, IntegratedGrouping, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler, WeekView, DayView, Appointments, Toolbar, DateNavigator, ViewSwitcher,
    AppointmentForm, AppointmentTooltip, TodayButton, GroupingPanel, Resources,
    DragDropProvider, EditRecurrenceMenu, AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import { AppointmentResponse } from '../../../store/appointment/types';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { storeChatData } from '../../../store/chat/action';
import { toast } from 'react-toastify';
import { CHAT, AVAILABILTY } from '../../../constants';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { getCookie } from '../../../utils/cookies';
import { print, removeCountryCode } from '../../../utils/global';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import apiConfigs from '../../../configs/apiConfigs';
import { userLogout } from '../../../store/login/actions';
import _ from 'lodash';
import { storeQuickChatData } from '../../../store/quickChat/action';
import { IAppState } from '../../../store';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { changeAppointmentPMSStatus, checkAppointmentBook, editAppointment, firestoreChangeNotificationToServer, removeBlockSlot, requestForMedicalHistory, sendManualReview } from '../../../v2/services/appointment';
import { CreateAppointmentModal } from '../../../v2/components/mySchedule/CreateAppointmentModal';
import { CancelAppointmentModal } from '../../../v2/components/mySchedule/CancelAppointmentModal';
import { confirmAlert } from 'react-confirm-alert';
import { ScheduleReScheduleModal } from '../../../v2/pages/myschedule/ScheduleReScheduleModal';
import configs from '../../../v2/configs/apiConfigs';
import { UserDataResponse } from '../../../store/userData/types';
import { checkProviderAvailable } from '../../../v2/utils/apiHandler';
import { DigitalDoc } from '../../../v2/components/digitaldoc/DigitalDoc';
import { callWaitlistApi } from '../../../store/appointment/actions';
import CONSTANT_LABELS from '../../../constants/labels';
import { ShowCallOption } from '../../../v2/components/voice/ShowCallOption';
import { VoiceCall } from '../../../v2/components/voice/VoiceCall';
import { VoiceCallMango } from '../../../v2/components/voice/VoiceCallMango';
import { VoiceCallGoto } from '../../../v2/components/voice/VoiceCallGoto';
import { NoteModal } from '../../note/NoteModal';
import { PaymentState } from '../../../store/payment/types';
import InvoiceModal from '../../payment/InvoiceModal';
import { DocumentUpload } from '../../../v2/components/documentsUpload/DocumentUpload';
interface Props {
    data: AppointmentResponse;
    location: any;
    match: any;
    history: any;
    handleRequestForPrescreening: (appointment: any) => void;
    selectedClinic?: string;
    callFetchApi: (startDate: any, endDate: any, view: string) => void;
    onChangeTime: (time: any, item: any) => void;
    chatData: typeof storeChatData;
    onDateChange: (date: any, item: any) => void;
    doLoading: typeof fetchLoadingPending;
    loadingSuccess: typeof fetchLoadingSuccess;
    logout: typeof userLogout;
    modelType?: string;
    clinics: any;
    onChangeEndTime: (time: any, item: any) => void;
    userData: UserDataResponse;
    masterPMSStatusList?: any[];
    callWaitlistApi: typeof callWaitlistApi;
    paymentState?: PaymentState;
    serviceType?: any;
}
interface State {
    events: any[];
    showScheduleCalendarModal: boolean;
    date: any;
    visible: boolean;
    view: string;
    isTimeEdit: boolean;
    edtTime: any;
    appointmentMeta: any;
    isCall: any;
    isDateEdit: boolean;
    showTime: any;
    editedTime: any
    editedDate: any;
    chatModal: boolean;
    chatData: any;
    chatMessageInput: any
    grouping: any[];
    resources: any[];
    providerData: any[];
    isEndTime: boolean;
    isStartTime: boolean;
    isStartTimeEdit: boolean;
    isEndTimeEdit: boolean;
    showEndTime: any;
    endEditedTime: any;
    showCancelModal: boolean;
    currentActiveModalAppointment: any;
    scheduleAppointmentModal: boolean;
    digitalDocShow: boolean;
    openVoiceCallOption: boolean;
    openVoiceCall: boolean;
    callType: string;
    callTo: string;
    showNoteModal: boolean;
    viewPaymentModal: boolean;
    invoiceModalOperation: string;
    showMore?: any;
    showDocumentsModal?: boolean;
}
class ScheduleNewCalendar extends React.Component<Props, State> {
    startDate = '';
    endDate = '';
    previous = '';
    oldData: any;
    db: any;
    chatFullData: any;
    unsubscribe: any
    messageInput: any;
    startTime = '';
    endTime = '';
    providerId = '';
    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
            showScheduleCalendarModal: false,
            date: new Date(),
            visible: false,
            view: 'Day',
            isTimeEdit: false, edtTime: '',
            appointmentMeta: '', isCall: false, isDateEdit: false,
            showTime: '', editedTime: '', editedDate: '', chatModal: false, chatData: '',
            chatMessageInput: '',
            grouping: [{
                resourceName: 'providerId',
            }],
            providerData: [
                { text: 'Low Priority', id: 1 },
                { text: 'High Priority', id: 2 },
            ],
            resources: [{
                fieldName: 'providerId',
                title: 'Provider',
                instances: [],
            }],
            isEndTime: false,
            isStartTime: false, isStartTimeEdit: false, isEndTimeEdit: false, showEndTime: '', endEditedTime: '',
            showCancelModal: false,
            currentActiveModalAppointment: null, scheduleAppointmentModal: false, digitalDocShow: false,
            openVoiceCallOption: false,
            openVoiceCall: false,
            callType: '',
            callTo: '',
            showNoteModal: false,
            viewPaymentModal: false,
            invoiceModalOperation: '',
            showMore: {
                open: false,
                id: ''
            },
            showDocumentsModal: false
        };
    }
    componentDidMount() {
        this.db = firebase.firestore();
        this.createEventFromExistingAppointment(this.props);
        $('.hide-date').closest('tr').next('tr').addClass('d-none');
        if (this.props.clinics) {
            const providerData = this.props.clinics.map((item: any) => { return { text: item.fullname, id: Number(item.uniqueid), } });
            let resourcesIntanceUpdate = this.state.resources[0];
            resourcesIntanceUpdate = {
                ...resourcesIntanceUpdate,
                instances: providerData
            };
            this.setState({ resources: [resourcesIntanceUpdate] });
        }
    }
    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (this.props.data !== nextProps.data) {
            this.createEventFromExistingAppointment(nextProps);
        }
        if (this.props.selectedClinic !== nextProps.selectedClinic) {
            if (this.state.view === 'Day') {
                this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
            } else {
                this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
            }
        }
        if (this.props.clinics !== nextProps.clinics) {
            const providerData = nextProps.clinics.map((item: any) => { return { text: item.fullname, id: Number(item.uniqueid), } });
            let resourcesIntanceUpdate = this.state.resources[0];
            resourcesIntanceUpdate = {
                ...resourcesIntanceUpdate,
                instances: providerData
            };
            this.setState({ resources: [resourcesIntanceUpdate] });
            $('.hide-date').closest('tr').next('tr').addClass('d-none');
        }
        return true;
    }
    createEventFromExistingAppointment(props: any) {
        if (props.data && props.data.appointments) {
            const { appointments } = props.data;
            if (appointments) {
                this.setState({
                    events: appointments.map((value: any) => {
                        if (value.type === "slot") {
                            if (value.mode === "partial") {
                                return {
                                    title: value.appointmentType ? value.appointmentType : '',
                                    description: value.dependant.fname,
                                    startDate: moment(value.date + " " + value?.metadata?.startTime, 'YYYY-MM-DD LT').toDate(),
                                    endDate: moment(value.date + " " + value?.metadata?.endTime, 'YYYY-MM-DD LT').toDate(),
                                    extendedProps: {
                                        pName: (value.requests && value.requests.appointmentFor && value.requests.appointmentFor === "other" && value.requests.dependantName !== "") ? value.requests.dependantName + " - " + value.requests.dependantRelation : value.dependant.fname + " " + value.dependant.lname,
                                        pEmail: value.dependant.email,
                                        pMobile: value.dependant.phone,
                                        dName: value.doctor.fullname,
                                        dUId: value.doctor.uniqueid,
                                        appointment: value,
                                        type: value.type,
                                        currentStatus: value.currentStatus,
                                        data: value,
                                    },
                                    providerId: Number(value.doctor.uniqueid),
                                    id: value.id
                                }
                            } else if (value.mode === "full") {
                                return {
                                    title: value.appointmentType ? value.appointmentType : '',
                                    description: value.dependant.fname,
                                    startDate: moment(value.date + ' 07:00 AM', 'YYYY-MM-DD LT').toDate(),
                                    endDate: moment(value.date + ' 09:00 PM', 'YYYY-MM-DD LT').toDate(),
                                    extendedProps: {
                                        pName: (value.requests && value.requests.appointmentFor && value.requests.appointmentFor === "other" && value.requests.dependantName !== "") ? value.requests.dependantName + " - " + value.requests.dependantRelation : value.dependant.fname + " " + value.dependant.lname,
                                        pEmail: value.dependant.email,
                                        pMobile: value.dependant.phone,
                                        dName: value.doctor.fullname,
                                        dUId: value.doctor.uniqueid,
                                        appointment: value,
                                        type: value.type,
                                        currentStatus: value.currentStatus,
                                        data: value,
                                    },
                                    providerId: Number(value.doctor.uniqueid),
                                    id: value.id
                                }
                            }
                        } else if (value.type === "blocktime") {
                            return {
                                title: '',
                                description: '',
                                startDate: moment(value.startdate + " " + value.starttime, 'YYYY-MM-DD LT').toDate(),
                                endDate: moment(value.enddate + " " + value.endtime, 'YYYY-MM-DD LT').toDate(),
                                extendedProps: {
                                    pName: '',
                                    pEmail: '',
                                    pMobile: '',
                                    dName: '',
                                    dUId: '',
                                    appointment: value,
                                    type: value.type,
                                    currentStatus: '',
                                    data: value,
                                },
                                providerId: Number(value.providerId),
                                id: value.id
                            }
                        }
                        return {
                            title: value.appointmentType ? value.appointmentType : '',
                            description: value.dependant.fname,
                            startDate: moment(value.date + " " + value.time, 'YYYY-MM-DD LT').local().toDate(),
                            endDate: moment(value.date + " " + value.time, 'YYYY-MM-DD LT').add(value.duration ? value.duration : "30", "minutes").local().toDate(),
                            time: value.time,
                            extendedProps: {
                                pName: (value.requests && value.requests.appointmentFor && value.requests.appointmentFor === "other" && value.requests.dependantName !== "") ? value.requests.dependantName + " - " + value.requests.dependantRelation : value.dependant.fname + " " + value.dependant.lname,
                                pEmail: value.dependant.email,
                                pMobile: value.dependant.phone,
                                dName: value.doctor.fullname,
                                dUId: value.doctor.uniqueid,
                                appointment: value,
                                type: value.type,
                                currentStatus: value.currentStatus,
                                data: value,
                            },
                            providerId: Number(value.doctor.uniqueid),
                            id: value.id
                        }
                    }), isCall: true
                }, () => {
                });
            }
        }
    }
    closeScheduleCalendarModal(date: any) {
        this.setState({ showScheduleCalendarModal: false, date: date ? date : new Date() });
    }
    appointment = (props: any) => {
        let appointmentStatusClass = '';
        let appointmentdata = props.data.extendedProps.data;
        if (appointmentdata.type && appointmentdata.type === "slot") {
            appointmentStatusClass = 'ms-block-time';
        } else if (
            appointmentdata.currentStatus === "scheduled" && appointmentdata.metadata && appointmentdata.metadata.appointment &&
            appointmentdata.metadata.appointment.checkin &&
            appointmentdata.metadata.appointment.checkin.attempt
        ) {
            appointmentStatusClass = 'ms-checkin';
        } else if (appointmentdata.currentStatus === "scheduled" && appointmentdata.metadata && appointmentdata.metadata.appointment &&
            appointmentdata.metadata.appointment.prescreening &&
            appointmentdata.metadata.appointment.prescreening.attempt &&
            appointmentdata.metadata.appointment.prescreening.result === 'Pass'
        ) {
            appointmentStatusClass = 'ms-prescreen-pass';
        } else if (appointmentdata.currentStatus === "scheduled" && appointmentdata.metadata && appointmentdata.metadata.appointment &&
            appointmentdata.metadata.appointment.prescreening &&
            appointmentdata.metadata.appointment.prescreening.attempt &&
            appointmentdata.metadata.appointment.prescreening.result === 'Fail') {
            appointmentStatusClass = 'ms-prescreen-fail';
        } else if (appointmentdata.currentStatus === 'scheduled' &&
            appointmentdata.metadata && appointmentdata.metadata.appointment &&
            appointmentdata.metadata.appointment.floating) {
            appointmentStatusClass = 'ms-floating';
        } else if (appointmentdata.currentStatus === 'scheduled' &&
            appointmentdata.metadata && appointmentdata.metadata.appointment &&
            appointmentdata.metadata.appointment.confirmation &&
            appointmentdata.metadata.appointment.confirmation.status === 'cancelled'
        ) {
            appointmentStatusClass = 'ms-cancel';
        } else if (appointmentdata.currentStatus === 'scheduled' &&
            appointmentdata.metadata && appointmentdata.metadata.appointment &&
            appointmentdata.metadata.appointment.confirmation &&
            appointmentdata.metadata.appointment.confirmation.status === 'confirmed'
        ) {
            appointmentStatusClass = 'ms-confirm';
        } else if (appointmentdata.currentStatus === 'scheduled') {
            appointmentStatusClass = 'ms-schedule'
        }
        else if (appointmentdata.currentStatus === 'requested') {
            appointmentStatusClass = 'ms-waitlist';
        } else if (appointmentdata.currentStatus === 'waitlist') {
            appointmentStatusClass = 'ms-waitlist';
        } else if (appointmentdata.currentStatus === 'completed') {
            appointmentStatusClass = 'ms-completed';
        }
        return (
            <Appointments.Appointment {...props} className={appointmentStatusClass} style={{
                backgroundColor: '#D6EFF7',
                borderRadius: '5px',
                border: '1px solid #5BC0DE',
                borderLeft: '5px solid #5BC0DE',
            }} key={appointmentdata.id + "appointment"}>
                <div className="chip_cc">
                    <div className="fw-medium">{props.data ? props.data.extendedProps.pName : ''}
                        {
                            appointmentdata.dependant && !appointmentdata.dependant.phone && !appointmentdata.patient.phone &&
                            (appointmentdata.currentStatus === 'waitlist' || appointmentdata.currentStatus === 'requested' ||
                                appointmentdata.currentStatus === 'scheduled') &&
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Manual followup
                                    </Tooltip>
                                }
                            >
                                <button type="button" className="btn" onClick={() => { }}>
                                    <i className="bi bi-telephone" style={{ fontSize: "12px" }}></i>
                                </button>
                            </OverlayTrigger>
                        }
                    </div>
                    {
                        (appointmentdata.type && appointmentdata.type === "slot") ?
                            <>
                                {
                                    appointmentdata.mode === "partial" &&
                                    <div className="text-muted">
                                        {appointmentdata.metadata.startTime}-{appointmentdata.metadata.endTime}</div>
                                }
                                {
                                    appointmentdata.mode === "full" &&
                                    <div className="text-muted">
                                        {"07:00 AM - 09:00 PM"}</div>
                                }
                                {
                                    appointmentdata.notes &&
                                    <div className="text-muted">{appointmentdata.notes}</div>
                                }
                            </>
                            :
                            <>
                                {
                                    props.data.time !== '' && props.data.time !== null &&
                                    <div className="text-muted">
                                        {props.data ? props.data.time : ''}-{moment(props.data.endDate).format('LT')}</div>
                                }
                            </>
                    }
                    <div className="text-muted">
                        {appointmentdata && appointmentdata?.procedure && Array.isArray(appointmentdata.procedure) &&
                            appointmentdata.procedure.slice(0, 3).map((item: any, index: number) => {
                                return (
                                    <React.Fragment key={index + "proceedure"}>
                                        <label>{item.category} ({item.code})</label>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </Appointments.Appointment>
        )
    }
    rowComponent = (props: any) => {
        $('.hide-date').closest('tr').next('tr').addClass('d-none');
        const dayOfWeek = moment(props.startDate).format('ddd').toUpperCase();
        const provider = this.props.clinics.find((item: any) => props.groupingInfo[0].id == item.uniqueid);
        const hour = provider.hours.find((item: any) => dayOfWeek === item.id);
        let isShaded = false;
        if (provider.blockTime && Array.isArray(provider.blockTime)) {
            let blocked = provider.blockTime.find((item: any) => {
                return (moment(props.startDate).isSameOrAfter(moment(item.startdate + " " + item.starttime, "YYYY-MM-DD LT")) &&
                    moment(props.endDate).isSameOrBefore(moment(item.enddate + " " + item.endtime, "YYYY-MM-DD LT")));
            });
            if (blocked !== undefined) {
                isShaded = true;
            }
        }
        if (hour) {
            return (
                <DayView.TimeTableCell {...props.children}
                    className={((hour.startHour === 'Closed' || hour.startHour === 'Clinic closed' || isShaded) ? 'disabled' : '')}
                    isShaded={(hour.startHour === 'Closed' || hour.startHour === 'Clinic closed' || isShaded)}
                    onClick={(e: any) => {
                        if (hour.startHour === 'Closed' || hour.startHour === 'Clinic closed' || isShaded) {
                            return;
                        }
                        if (this.props.userData?.accessEnabled?.pms_integration === true) {
                            if (this.props.userData?.clinics[0].tempwriteBack === "on" && this.props.userData?.clinics[0].appointmentwriteback === "on") {
                                this.startTime = props.startDate;
                                this.endTime = props.endDate;
                                this.providerId = props.groupingInfo[0].id;
                                props.onDoubleClick(e);
                            }
                            return;
                        }
                        if (this.props.userData?.accessEnabled?.pms_integration === true) {
                            if (this.props.userData?.clinics[0].writeBack === "off") {
                                return;
                            } else if (this.props.userData?.clinics[0].writeBack === "on" &&
                                this.props.userData?.clinics[0].appointmentwriteback === "off") {
                                return;
                            }
                        }
                        this.startTime = props.startDate;
                        this.endTime = props.endDate;
                        this.providerId = props.groupingInfo[0].id;
                        props.onDoubleClick(e)
                    }} />
            )
        }
        return (
            <DayView.TimeTableCell {...props.children}
                className={'disabled'}
                isShaded={true}
                onClick={(e: any) => {
                    return;
                }} />
        )
    }
    cellComponent = (props: any) => {
        const dayOfWeek = moment(props.startDate).format('ddd').toUpperCase();
        const hour = this.props.userData.clinics[0].hours.find((item: any) => item.id === dayOfWeek);
        if (hour) {
            return (
                <WeekView.TimeTableCell {...props.children}
                    className={(hour.startHour === 'Closed' ? 'disabled' : '')}
                    isShaded={hour.startHour === 'Closed'}
                    onClick={(e: any) => {
                        if (hour.startHour === 'Closed') {
                            return;
                        }
                        if (this.props.userData?.accessEnabled?.pms_integration === true) {
                            if (this.props.userData?.clinics[0].writeBack === "off") {
                                return;
                            } else if (this.props.userData?.clinics[0].writeBack === "on" &&
                                this.props.userData?.clinics[0].appointmentwriteback === "off") {
                                return;
                            }
                        }
                        this.setState({
                            date: moment(props.startDate).format('YYYY-MM-DD')
                        }, () => {
                            this.startTime = props.startDate;
                            this.endTime = props.endDate;
                            props.onDoubleClick(e)
                        });
                    }} />
            )
        } else {
            return (
                <WeekView.TimeTableCell {...props.children}
                    className={'disabled'}
                    isShaded={true}
                    onClick={(e: any) => {
                        return;
                    }} />
            )
        }
    }
    onChangeTime = (time: any, item: any) => {
        // let date = moment(time).format('hh:mm') + ' - ' + moment(time).add('15', 'minute').format('hh:mm')
        let date = moment(time).format('LT');
        // this.props.onChangeTime(time,item);
        this.setState({ showTime: date, edtTime: date, isStartTimeEdit: false, editedTime: time })
    }
    onChangeEndTime = (time: any, item: any) => {
        // let date = moment(time).format('hh:mm') + ' - ' + moment(time).add('15', 'minute').format('hh:mm')
        let date = moment(time).format('LT');
        // this.props.onChangeTime(time,item);
        this.setState({ showEndTime: date, edtTime: date, isEndTimeEdit: false, endEditedTime: time })
    }
    handleChangeDate = (date: any, item: any) => {
        // this.props.onDateChange(date,item);
        this.setState({ editedDate: date, isDateEdit: false })
    }
    handleUpdate = async (item: any, props: any) => {
        let selectedClinic = this.props.userData.clinics[0];
        let selectedProvider = this.props.userData.clinics[0].provider.find((value: any) => value.id === item.doctor.id);
        if (this.state.editedTime) {
            this.props.onChangeTime(this.state.editedTime, item);
        }
        if (this.state.endEditedTime) {
            this.props.onChangeEndTime(this.state.endEditedTime, item);
        }
        if (this.state.editedDate) {
            this.props.onDateChange(this.state.editedDate, item);
        }
        const date: string = moment(this.state.editedDate ? this.state.editedDate : props.appointmentData.startDate).format("YYYY-MM-DD");
        const time: string = moment(this.state.editedTime ? this.state.editedTime : props.appointmentData.startDate).format("LT");
        const startTime: string = moment(this.state.editedTime ? this.state.editedTime : props.appointmentData.startDate).format("LT");
        const endTime: string = moment(this.state.endEditedTime ? this.state.endEditedTime : props.appointmentData.endDate).format("LT");
        const beforeTime = moment(date + " " + time, 'YYYY-MM-DD LT').utc();
        const afterTime = moment(moment().utc());
        if (!moment(beforeTime, 'YYYY-MM-DD LT').isAfter(moment(afterTime, 'YYYY-MM-DD LT'))) {
            toast.warn('Date and time should be greater than current date and time');
            return;
        }
        if (moment(this.state.endEditedTime, "LT").isBefore(moment(this.state.editedTime, "LT"))) {
            toast.warn('End time should not be previous.');
            return;
        }
        let availableDoctor = checkProviderAvailable(selectedClinic, selectedProvider, date, startTime, endTime);
        if (availableDoctor === false) {
            toast.warn('Provider is not available on selected time.');
            return;
        }
        this.props.doLoading();
        checkAppointmentBook({
            appointmentId: item.uniqueid,
            provider: props.appointmentData.extendedProps.data.doctor.uniqueid,
            date: date,
            starttime: startTime,
            endtime: endTime
        }).then(async (success) => {
            if (success && success.status && success.status.error === false) {
                var objIndex: string = props.appointmentData.extendedProps.data.currentStatus.toUpperCase();
                const fromStatus = CHAT.FROM_STATUS[objIndex];
                const obj = {
                    "appointmentId": Number(props.appointmentData.extendedProps.data.uniqueid),
                    "senderId": Number(getCookie().xpr_user_id),
                    "sender": CHAT.SENDER.USER,
                    "action": `Changed status from ${fromStatus} to ${fromStatus}.`,
                    "actionType": 'STATUS',
                    "dateTime": {
                        "date": date,
                        "time": time,
                        "startTime": startTime,
                        "endTime": endTime
                    },
                    "text": 'Testing Static',
                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "fromStatus": fromStatus,
                    "toStatus": fromStatus,
                    "sendToCId": props.appointmentData.extendedProps.data.clinic.uniqueid,
                    "dependantId": Number(props.appointmentData.extendedProps.data.dependant.uniqueid),
                    "firstName": this.props.userData.firstName,
                    "lastName": this.props.userData.lastName,
                };
                print(obj);
                this.props.doLoading();
                try {
                    const response = await this.db.collection(apiConfigs.CHAT_COLLECTION).add(obj);
                    if (response) {
                        response.get().then((docRef: any) => {
                            let data = {
                                ...docRef.data(),
                                mode: "create",
                                id: docRef.id
                            };
                            firestoreChangeNotificationToServer(data).then((success) => {
                                this.props.loadingSuccess(false);
                                this.setState({ visible: false });
                                if (this.state.view === 'Day') {
                                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                } else {
                                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                }
                            }).catch((err) => {
                                toast.error('Update failed');
                            });
                        });
                    }
                } catch (err) {
                    this.props.loadingSuccess(false);
                    toast.error('Update failed');
                }
            } else {
                this.props.loadingSuccess(false);
            }
        }).catch((err) => {
            this.props.loadingSuccess(false);
            toast.error(err.msg);
        })
    }
    handleChatModal = (item: any) => {
        this.props.chatData({
            ...item,
            appointmentId: item.uniqueid,
            sendToCId: item.clinic.uniqueid,
            pinType: "chat",
            openPin: true,
            fetched: true
        });
    }
    scheduleAppointmentActionClick = (item: any) => {
        this.setState({ scheduleAppointmentModal: true, currentActiveModalAppointment: item });
    }
    doManualPrescreenVerification = async (item: any) => {
        const obj = {
            "appointmentId": Number(item.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.PVBC,
            "actionType": CHAT.ACTION_TYPE.PRESCREENING_STATUS,
            "text": 'Manual prescreen verified',
            "isPrescreenManual": true,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": item.clinic.uniqueid,
            "sendToDId": item.doctor.uniqueid,
            "dependantId": Number(item.dependant.uniqueid),
            "dateTime": {
                "date": item.date,
                "time": item.time
            },
            "firstName": this.props.userData.firstName,
            "lastName": this.props.userData.lastName,
        };
        try {
            this.props.doLoading();
            this.db.collection(configs.CHAT_COLLECTION)
                .where("appointmentId", "==", item.uniqueid)
                .where("actionType", "==", CHAT.ACTION_TYPE.PRESCREENING)
                .where("metadata.viewByClinicAt", "==", null)
                .get().then((querySnapshot: any) => {
                    querySnapshot.forEach((doc: any) => {
                        const docRef = this.db.collection(configs.CHAT_COLLECTION).doc(doc.id);
                        docRef.update({
                            "metadata.viewByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
                        });
                    });
                });
            const response = await this.db.collection(apiConfigs.CHAT_COLLECTION).add(obj);
            if (response) {
                response.get().then((docRef: any) => {
                    let data = {
                        ...docRef.data(),
                        mode: "create",
                        id: docRef.id
                    };
                    firestoreChangeNotificationToServer(data).then((success) => {
                        this.props.loadingSuccess(false);
                        toast.success('Manual prescreening verification successfull.');
                        this.setState({ visible: false });
                        if (this.state.view === 'Day') {
                            this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                        } else {
                            this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                        }
                    }).catch((err) => {
                        toast.error('Manual prescreening verification failed.');
                        this.props.loadingSuccess(false);
                    });
                });
            } else {
                toast.error('Manual prescreening verification failed.');
                this.props.loadingSuccess(false);
            }
        } catch (err) {
            toast.error('Manual prescreening verification failed.');
            this.props.loadingSuccess(false);
        }
    }
    prescreenManualVerificationAlert = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you have completed manual prescreening for this patient.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        this.doManualPrescreenVerification(item);
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    removeBlockTime = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to remove this blocked time.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        this.props.doLoading();
                        removeBlockSlot(this.props.selectedClinic, item.id).then((success) => {
                            this.props.loadingSuccess(false);
                            if (success && success.status && success.status.error === false) {
                                this.setState({ visible: false });
                                if (this.state.view === 'Day') {
                                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                } else {
                                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                }
                                return;
                            }
                            toast.error('Remove block time failed.');
                        }).catch((err) => {
                            this.props.loadingSuccess(false);
                            toast.error(err.msg);
                        })
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    handleConfirmByPatient = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to confirm patient visit?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: async () => {
                        this.props.doLoading();
                        const obj = {
                            "appointmentId": Number(item.uniqueid),
                            "senderId": Number(getCookie().xpr_user_id),
                            "sender": CHAT.SENDER.USER,
                            "action": CHAT.ACTION.CBP,
                            "actionType": CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT,
                            "text": 'Confirm by patient',
                            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "sendToCId": item.clinic.uniqueid,
                            "sendToDId": item.doctor.uniqueid,
                            "dependantId": Number(item.dependant.uniqueid),
                            "dateTime": {
                                "date": item.date,
                                "time": item.time
                            },
                            "firstName": this.props.userData.firstName,
                            "lastName": this.props.userData.lastName,
                        };
                        try {
                            const response = await this.db.collection(apiConfigs.CHAT_COLLECTION).add(obj);
                            if (response) {
                                response.get().then((docRef: any) => {
                                    let data = {
                                        ...docRef.data(),
                                        mode: "create",
                                        id: docRef.id
                                    };
                                    firestoreChangeNotificationToServer(data).then((success) => {
                                        this.props.loadingSuccess(false);
                                        this.setState({ visible: false });
                                        if (this.state.view === 'Day') {
                                            this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                        } else {
                                            this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                        }
                                        toast.success('Appt. confirm by patient successful.');
                                    }).catch((err) => {
                                        toast.error('Appt. confirm by patient failed.');
                                    });
                                });
                            }
                        } catch (err) {
                            toast.error('Appt. confirm by patient failed.');
                            this.props.loadingSuccess(false);
                        }
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    handlePMSStatusChange = async (item: any, oldvalue: any, newvalue: any) => {
        try {
            const response = await changeAppointmentPMSStatus(item.clinic.uniqueid, item.uniqueid, {
                oldValue: oldvalue, newValue: newvalue
            });
            if (response && response.status && response.status.error === false) {
                if (this.state.view === 'Day') {
                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                } else {
                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                }
                toast.success('Appointment pms status change success.');
            }
        } catch (err: any) {
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Appointment pms status change failed.');
            }
        }
    }
    markApptDone = async (appointment: any) => {
        try {
            confirmAlert({
                title: '',
                message: "Are you sure you want to mark appointment as done?",
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-outline-primary',
                        onClick: async () => {
                            this.props.loadingSuccess(true);
                            const response = await editAppointment({
                                type: "archive"
                            }, this.props.userData.clinics[0].uniqueId, appointment.uniqueid);
                            this.props.loadingSuccess(false);
                            if (response && response.status && response.status.error === false) {
                                this.setState({ visible: false }, () => {
                                    if (this.state.view === 'Day') {
                                        this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                    } else {
                                        this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                    }
                                    this.props.callWaitlistApi();
                                });
                            } else {
                                toast.error(response.status.msg);
                                this.props.loadingSuccess(false);
                            }
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-outline-primary',
                        onClick: () => {
                        }
                    }
                ]
            });
        } catch (err: any) {
            this.props.loadingSuccess(false);
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Mark appt done failed.');
            }
        }
    }
    manualReview = async (appointment: any) => {
        try {
            confirmAlert({
                title: '',
                message: "This action will send review request to patient, please confirm.",
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-outline-primary',
                        onClick: async () => {
                            try {
                                this.props.loadingSuccess(true);
                                const response = await sendManualReview(appointment.uniqueid, this.props.userData.clinics[0].uniqueId,);
                                this.props.loadingSuccess(false);
                                if (response && response.status && response.status.error === false) {
                                    this.setState({ visible: false }, () => {
                                        if (this.state.view === 'Day') {
                                            this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                        } else {
                                            this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                        }
                                        this.props.callWaitlistApi();
                                    });
                                } else {
                                    toast.error(response.status.msg);
                                    this.props.loadingSuccess(false);
                                }
                            } catch (err: any) {
                                toast.error("Review sent failed");
                                this.props.loadingSuccess(false);
                            }
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-outline-primary',
                        onClick: () => {
                        }
                    }
                ]
            });
        } catch (err: any) {
            this.props.loadingSuccess(false);
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Mark appt done failed.');
            }
        }
    }
    handleVoiceCall = (appointment: any) => {
        appointment = {
            ...appointment,
            sendToCId: appointment.clinic.uniqueid,
            appointmentId: appointment.uniqueid
        }
        this.setState({
            currentActiveModalAppointment: appointment,
            openVoiceCallOption: true
        });
    }
    async handlePaymentRequest(appointment: any) {
        if (appointment) {
            this.props.doLoading();
            const obj = {
                "appointmentId": Number(appointment.uniqueid),
                "senderId": Number(getCookie().xpr_user_id),
                "sender": CHAT.SENDER.USER,
                "action": CHAT.ACTION.PRFC,
                "actionType": CHAT.ACTION_TYPE.PAYMENT_REQUEST,
                "text": appointment.clinic.name + ' requested ' + appointment.billingdata.clinicToPatientBilling.total + ' amount of payment from you.',
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": appointment.clinic.uniqueid,
                "sendToDId": appointment.doctor.uniqueid,
                "dependantId": Number(appointment.dependant.uniqueid),
                "dateTime": {
                    "date": appointment.scheduledDate,
                    "time": appointment.scheduledTime
                },
                "firstName": this.props.userData.firstName,
                "lastName": this.props.userData.lastName,
            };
            try {
                const response = await this.db.collection(apiConfigs.CHAT_COLLECTION).add(obj);
                if (response) {
                    response.get().then((docRef: any) => {
                        let data = {
                            ...docRef.data(),
                            mode: "create",
                            id: docRef.id
                        };
                        firestoreChangeNotificationToServer(data).then((success) => {
                            this.props.loadingSuccess(false);
                            if (this.state.view === 'Day') {
                                this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                            } else {
                                this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                            }
                            toast.success('Payment request sent successfully.');
                        }).catch((err) => {
                            this.props.loadingSuccess(false);
                            toast.error('Payment request sent failed.');
                        })
                    });
                }
            } catch (err) {
                this.props.loadingSuccess(false);
                toast.error('Payment request sent failed.');
            }
        }
    }
    viewContent = (props: any) => {
        console.log(this.state);
        if (props.appointmentData && props.appointmentData.extendedProps) {
            let doctorName = '';
            let patientName = '';
            if (props.appointmentData && props.appointmentData.extendedProps.pName) {
                var matches = props.appointmentData.extendedProps.pName.match(/\b(\w)/g);
                patientName = (matches[0] ? matches[1] ? matches[0] + matches[1] : matches[0] : '');
            }
            if (props.appointmentData && props.appointmentData.extendedProps.data.doctor.fullname) {
                var matches = props.appointmentData.extendedProps.data.doctor.fullname.match(/\b(\w)/g);
                doctorName = (matches[1] ? matches[2] ? matches[1] + matches[2] : matches[1] : '');
            }
            let appointmentStatusClass = '';
            let appointmentdata = props.appointmentData.extendedProps.data;
            if (appointmentdata.type && appointmentdata.type === "slot") {
                appointmentStatusClass = 'status-block-time';
            } else if (
                appointmentdata.currentStatus === "scheduled" && appointmentdata.metadata && appointmentdata.metadata.appointment &&
                appointmentdata.metadata.appointment.checkin &&
                appointmentdata.metadata.appointment.checkin.attempt
            ) {
                appointmentStatusClass = 'status-checkin';
            } else if (appointmentdata.currentStatus === "scheduled" && appointmentdata.metadata && appointmentdata.metadata.appointment &&
                appointmentdata.metadata.appointment.prescreening &&
                appointmentdata.metadata.appointment.prescreening.attempt &&
                appointmentdata.metadata.appointment.prescreening.result === 'Pass'
            ) {
                appointmentStatusClass = 'status-prescreen-pass';
            } else if (appointmentdata.currentStatus === "scheduled" && appointmentdata.metadata && appointmentdata.metadata.appointment &&
                appointmentdata.metadata.appointment.prescreening &&
                appointmentdata.metadata.appointment.prescreening.attempt &&
                appointmentdata.metadata.appointment.prescreening.result === 'Fail') {
                appointmentStatusClass = 'status-prescreen-fail';
            } else if (appointmentdata.currentStatus === 'scheduled' &&
                appointmentdata.metadata && appointmentdata.metadata.appointment &&
                appointmentdata.metadata.appointment.floating
            ) {
                appointmentStatusClass = 'status-floating';
            } else if (appointmentdata.currentStatus === 'scheduled' &&
                appointmentdata.metadata && appointmentdata.metadata.appointment &&
                appointmentdata.metadata.appointment.confirmation &&
                appointmentdata.metadata.appointment.confirmation.status === 'cancelled'
            ) {
                appointmentStatusClass = 'status-cancel';
            } else if (appointmentdata.currentStatus === 'scheduled' &&
                appointmentdata.metadata && appointmentdata.metadata.appointment &&
                appointmentdata.metadata.appointment.confirmation &&
                appointmentdata.metadata.appointment.confirmation.status === 'confirmed'
            ) {
                appointmentStatusClass = 'status-confirm';
            } else if (appointmentdata.currentStatus === 'scheduled') {
                appointmentStatusClass = 'status-schedule'
            }
            else if (appointmentdata.currentStatus === 'requested') {
                appointmentStatusClass = 'status-waitlist';
            } else if (appointmentdata.currentStatus === 'waitlist') {
                appointmentStatusClass = 'status-waitlist';
            } else if (appointmentdata.currentStatus === 'completed') {
                appointmentStatusClass = 'status-completed';
            }
            let takeWritebackBool = false;
            let apptScheduled = true;
            let apptReSchedule = true;
            let apptConfirmPatient = true;
            let apptComplete = true;
            let apptEdit = true;
            let apptCancel = true;
            let apptPMSStatus = true;
            console.log("PMS integration", this.props.userData?.accessEnabled?.pms_integration);
            let tempWriteBack = false;
            if (this.props.userData && this.props.userData.clinics && this.props.userData.clinics[0] &&
                this.props.userData.clinics[0].tempwriteBack === "on") {
                tempWriteBack = true;
            }
            if (this.props.userData?.accessEnabled?.pms_integration === true) {
                if (this.props.userData.clinics[0].writeBack === "on" &&
                this.props.userData.clinics[0].appointmentwriteback === "on") {
                    apptReSchedule = false;
                }
                if (this.props.userData.clinics[0].writeBack === "off") {
                    apptScheduled = false;
                    // apptReSchedule = true; // enabled for special case of SIKKA status sync issue
                    apptConfirmPatient = false;
                    apptComplete = true; // enabled for special case of SIKKA status sync issue
                    apptEdit = true; // enabled for special case of SIKKA status sync issue
                    apptCancel = true; //enabled for special case of SIKKA status sync issue
                    apptPMSStatus = false;
                } else if (this.props.userData.clinics[0].writeBack === "on" &&
                    this.props.userData.clinics[0].patientwriteback === "off" &&
                    this.props.userData.clinics[0].appointmentwriteback === "off" &&
                    this.props.userData.clinics[0].statuswriteback === "off") {
                    apptScheduled = false;
                    // apptReSchedule = false;
                    apptConfirmPatient = false;
                    apptComplete = false;
                    apptEdit = false;
                    apptCancel = false;
                    apptPMSStatus = false;
                } else if (this.props.userData.clinics[0].writeBack === "on" &&
                    this.props.userData.clinics[0].patientwriteback === "on" &&
                    this.props.userData.clinics[0].appointmentwriteback === "off" &&
                    this.props.userData.clinics[0].statuswriteback === "off") {
                    apptScheduled = false;
                    // apptReSchedule = false;
                    apptConfirmPatient = false;
                    apptComplete = false;
                    apptEdit = false;
                    apptCancel = false;
                    apptPMSStatus = false;
                } else if (this.props.userData.clinics[0].writeBack === "on" &&
                    this.props.userData.clinics[0].patientwriteback === "on" &&
                    this.props.userData.clinics[0].appointmentwriteback === "off" &&
                    this.props.userData.clinics[0].statuswriteback === "on") {
                    apptScheduled = false;
                    // apptReSchedule = false;
                    apptEdit = false;
                } else if (this.props.userData.clinics[0].writeBack === "on" &&
                    this.props.userData.clinics[0].patientwriteback === "off" &&
                    this.props.userData.clinics[0].appointmentwriteback === "off" &&
                    this.props.userData.clinics[0].statuswriteback === "on") {
                    apptScheduled = false;
                    // apptReSchedule = false;
                    apptEdit = false;
                } else if (this.props.userData.clinics[0].writeBack === "on" &&
                    this.props.userData.clinics[0].patientwriteback === "off" &&
                    this.props.userData.clinics[0].appointmentwriteback === "off" &&
                    this.props.userData.clinics[0].statuswriteback === "off") {
                    apptScheduled = false;
                    // apptReSchedule = false;
                    apptConfirmPatient = false;
                    apptComplete = false;
                    apptEdit = false;
                    apptCancel = false;
                    apptPMSStatus = false;
                } else if (this.props.userData.clinics[0].statuswriteback === "off") {
                    apptPMSStatus = false;
                }
            }
            if (this.props.userData.clinics[0] &&
                this.props.userData?.accessEnabled?.pms_integration === true &&
                (this.props.userData.clinics[0].writeBack === "on") &&
                appointmentdata.metadata && appointmentdata.metadata.pms && appointmentdata.metadata.pms.writeBackStatus && appointmentdata.metadata.pms.writeBackStatus === "Success") {
                takeWritebackBool = true;
            } else if (this.props.userData.clinics[0] &&
                this.props.userData?.accessEnabled?.pms_integration === true &&
                (this.props.userData.clinics[0].writeBack === "off")) {
                takeWritebackBool = true; // enabled for special case of SIKKA status sync issue
            } else if (this.props.userData.clinics[0] &&
                this.props.userData?.accessEnabled?.pms_integration === false &&
                this.props.userData.clinics[0].writeBack === "off" &&
                (!(appointmentdata.metadata && appointmentdata.metadata.pms && appointmentdata.metadata.pms.writeBackStatus) ||
                    (appointmentdata.metadata && appointmentdata.metadata.pms && appointmentdata.metadata.pms.writeBackStatus === "Success"))) {
                takeWritebackBool = true;
            }
            return (
                <div className="cal-pop-modify" key={appointmentdata.id + "viewcontent"}>
                    <ul className="list-group list-group-flush">
                        <li className={"list-group-item " + appointmentStatusClass}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex p-0">
                                    <div className="avatar flex-shrink-0 border rounded-circle me-2 bg-white text-dark">
                                        {patientName}
                                    </div>
                                    <div className="text-white">
                                        <span className="d-block fw-medium">{(props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.pName ?
                                            props.appointmentData.extendedProps.pName : '')}</span>
                                        <small className="d-block">{
                                            props.appointmentData ? props.appointmentData.extendedProps && props.appointmentData.extendedProps.currentStatus : ''}
                                            {
                                                (appointmentdata.currentStatus === 'scheduled' &&
                                                    appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                    appointmentdata.metadata.appointment.floating) &&
                                                '- floating'
                                            }
                                            {
                                                (appointmentdata.currentStatus === 'scheduled' &&
                                                    appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                    appointmentdata.metadata.appointment.confirmation &&
                                                    appointmentdata.metadata.appointment.confirmation.status === 'cancelled') &&
                                                '- cancelled'
                                            }
                                            {
                                                (appointmentdata.currentStatus === 'scheduled' &&
                                                    appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                    appointmentdata.metadata.appointment.confirmation &&
                                                    appointmentdata.metadata.appointment.confirmation.status === 'confirmed') &&
                                                '- confirmed'
                                            }
                                        </small>
                                    </div>
                                </div>
                                <button className="btn p-0 text-white" onClick={() => { this.setState({ visible: false, isStartTimeEdit: false, isDateEdit: false }) }}>
                                    <i className="bi bi-x"></i>
                                </button>
                            </div>
                        </li>
                        <small className="d-block text-center">
                            {
                                (appointmentdata.dependant &&
                                    appointmentdata.dependant.isAdministrativeNotification === "0") &&
                                <span>This patient will not receive notifications</span>
                            }
                        </small>
                        {props.appointmentData.extendedProps.data.doctor.fullname &&
                            <li className="list-group-item">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <small className="d-block text-muted">Provider</small>
                                        <div className="d-block fw-medium">{props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                            props.appointmentData.extendedProps.data.doctor && props.appointmentData.extendedProps.data.doctor.fullname}</div>
                                    </div>
                                </div>
                            </li>
                        }
                        {
                            (!(props.appointmentData.extendedProps.data.type === "slot" &&
                                props.appointmentData.extendedProps.data.mode === "full")) &&
                            <li className="list-group-item">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <small className="d-block text-muted">Start Time</small>
                                            <div>
                                                {
                                                    !this.state.showTime ?
                                                        <div className="d-block fw-medium">{props.appointmentData.startDate &&
                                                            <span>
                                                                {
                                                                    props.appointmentData.extendedProps.data.edtFullDate ?
                                                                        moment(props.appointmentData.extendedProps.data.edtFullDate).format('LT') :
                                                                        moment(props.appointmentData.startDate).format('LT')}
                                                            </span>}
                                                        </div>
                                                        :
                                                        <div className="d-block fw-medium">{this.state.showTime}</div>
                                                }
                                                <KeyboardTimePicker
                                                    margin="none"
                                                    id={props.appointmentData.extendedProps.data.id + "time-picker"}
                                                    name={props.appointmentData.extendedProps.data.id + "time-picker"}
                                                    value={this.state.editedTime ? this.state.editedTime : props.appointmentData && props.appointmentData.extendedProps.data.edtFullDate ? props.appointmentData.extendedProps.data.edtFullDate : props.appointmentData.startDate}
                                                    onChange={(date) => { this.onChangeTime(date, props.appointmentData.extendedProps.data) }}
                                                    open={this.state.isStartTimeEdit === true}
                                                    TextFieldComponent={(props) => { return null }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    onClose={() => {
                                                        this.setState({ isStartTimeEdit: false });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {props.appointmentData.extendedProps.data.type === "slot" ? null :
                                                <button className="btn p-1 ms-3" type="button" onClick={() => {
                                                    if (apptEdit && (props.appointmentData.extendedProps.data.currentStatus === 'completed' ||
                                                        props.appointmentData.extendedProps.data.currentStatus === 'cancelled' ||
                                                        (props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                            props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                            props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt))) {
                                                        return;
                                                    }
                                                    this.setState({ isStartTimeEdit: true });
                                                }}>
                                                    <i className="bi bi-clock"></i>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <small className="d-block text-muted">End Time</small>
                                                <div>
                                                    {
                                                        !this.state.showEndTime ?
                                                            <div className="d-block fw-medium">{props.appointmentData.endDate &&
                                                                <span>
                                                                    {
                                                                        props.appointmentData.extendedProps.data.edtEndFullDate ?
                                                                            moment(props.appointmentData.extendedProps.data.edtEndFullDate).format('LT') :
                                                                            moment(props.appointmentData.endDate).format('LT')}
                                                                </span>}
                                                            </div>
                                                            :
                                                            <div className="d-block fw-medium">{this.state.showEndTime}</div>
                                                    }
                                                    <KeyboardTimePicker
                                                        margin="none"
                                                        id={props.appointmentData.extendedProps.data.id + "time-picker"}
                                                        name={props.appointmentData.extendedProps.data.id + "time-picker"}
                                                        value={this.state.endEditedTime ? this.state.endEditedTime : props.appointmentData && props.appointmentData.extendedProps.data.edtEndFullDate ? props.appointmentData.extendedProps.data.edtEndFullDate : props.appointmentData.endDate}
                                                        onChange={(date) => { this.onChangeEndTime(date, props.appointmentData.extendedProps.data) }}
                                                        open={this.state.isEndTimeEdit === true}
                                                        TextFieldComponent={(props) => { return null }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                        onClose={() => {
                                                            this.setState({ isEndTimeEdit: false });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    props.appointmentData.extendedProps.data.type === "slot" ? null :
                                                        <button className="btn p-1 ms-3" type="button" onClick={() => {
                                                            if (apptEdit && (props.appointmentData.extendedProps.data.currentStatus === 'completed' ||
                                                                props.appointmentData.extendedProps.data.currentStatus === 'cancelled' ||
                                                                (props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                                    props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                                    props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt))) {
                                                                return;
                                                            }
                                                            this.setState({ isEndTimeEdit: true });
                                                        }}>
                                                            <i className="bi bi-clock"></i>
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        }
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center">
                                {
                                    <div>
                                        <small className="d-block text-muted">Date</small>
                                        <div className="d-block fw-medium">{props.appointmentData && props.appointmentData.startDate &&
                                            <span>{
                                                !this.state.editedDate ?
                                                    moment(props.appointmentData.startDate).format('LL')
                                                    :
                                                    moment(this.state.editedDate).format('LL')
                                            }</span>
                                        }
                                        </div>
                                    </div>
                                }
                                {props.appointmentData.extendedProps.data.metadata &&
                                    <div className="d-flex justify-content-between align-items-center miuif_margin">
                                        {
                                            this.state.isDateEdit &&
                                            <KeyboardDatePicker
                                                margin="none"
                                                id={props.appointmentData.extendedProps.data.id + "date-picker-dialog"}
                                                name={props.appointmentData.extendedProps.data.id + "date-picker-dialog"}
                                                format="YYYY/MM/DD"
                                                minDate={new Date()}
                                                value={moment(this.state.editedDate ? this.state.editedDate : props.appointmentData.extendedProps.data.edtDate ? props.appointmentData.extendedProps.data.edtDate : props.appointmentData.startDate)}
                                                onChange={(date) => { this.handleChangeDate(date, props.appointmentData.extendedProps.data); }}
                                                open={this.state.isDateEdit}
                                                TextFieldComponent={(props) => { return null }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                onClose={() => {
                                                    this.setState({ isDateEdit: false });
                                                }}
                                            />
                                        }
                                        <button className={!this.state.isDateEdit ? "btn p-1 ms-3" : "btn p-1 ms-3"}
                                            onClick={(e) => {
                                                if (apptEdit && (props.appointmentData.extendedProps.data.currentStatus === 'completed' ||
                                                    props.appointmentData.extendedProps.data.currentStatus === 'cancelled' ||
                                                    props.appointmentData.extendedProps.data.type ||
                                                    (props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt))) {
                                                    return;
                                                }
                                                this.setState({ isDateEdit: !this.state.isDateEdit })
                                            }}><i className={!this.state.isDateEdit ? "bi bi-calendar-check" : "bi bi-x"}></i></button>
                                    </div>
                                }
                            </div>
                        </li>
                        {
                            (appointmentdata.type && appointmentdata.type === "slot") &&
                            <li className="list-group-item">
                                <div>
                                    <small className="d-block text-muted">Notes</small>
                                    <div className="d-block fw-medium">{appointmentdata.notes ? appointmentdata.notes : '-'}</div>
                                </div>
                            </li>
                        }
                        {props.appointmentData.extendedProps.data.dependant.phone &&
                            <li className="list-group-item">
                                <div>
                                    <small className="d-block text-muted">Mobile Number</small>
                                    <div className="d-block fw-medium">{props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                        props.appointmentData.extendedProps.data.dependant && (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(props.appointmentData.extendedProps.data.dependant.phone))}
                                    </div>
                                </div>
                            </li>
                        }
                        {
                            (props.appointmentData.extendedProps.data.currentStatus !== 'requested' && props.appointmentData.extendedProps.data.metadata &&
                                !props.appointmentData.extendedProps.data.type &&
                                this.props.userData.clinics[0].covid_pre_screen)
                            &&
                            <>
                                <li className="list-group-item">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <small className="d-block text-muted">Prescreening</small>
                                            <div className="d-block fw-medium">
                                                <span className="fw-medium">Status</span> -
                                                {
                                                    (props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                        props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening.verifiedOn) &&
                                                    <span className="text-success fw-medium">
                                                        {
                                                            (props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                                props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening.isPrescreenManual) &&
                                                            <>Manually verified</>
                                                        }
                                                        {
                                                            (props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                                props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt &&
                                                                !props.appointmentData.extendedProps.data.metadata.appointment.prescreening.isPrescreenManual) &&
                                                            <>Verified</>
                                                        }
                                                    </span>
                                                }
                                                {
                                                    (props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                        props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt &&
                                                        !props.appointmentData.extendedProps.data.metadata.appointment.prescreening.verifiedOn) &&
                                                    <span className="text-info fw-medium"> Completed</span>
                                                }
                                                {
                                                    (!(props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                        props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt) &&
                                                        !(props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                            props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                            props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                            props.appointmentData.extendedProps.data.metadata.appointment.prescreening.verifiedOn)) &&
                                                    <span className="text-danger fw-medium"> Pending</span>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                (!(props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                    props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                    props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                    props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt) &&
                                                    (props.appointmentData.extendedProps.data.metadata &&
                                                        props.appointmentData.extendedProps.data.metadata.appointment &&
                                                        moment.duration(moment(props.appointmentData.extendedProps.data.date + " " + props.appointmentData.extendedProps.data.time, 'YYYY-MM-DD LT').add(props.appointmentData.extendedProps.duration, 'minutes').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                                        && moment.duration(moment(props.appointmentData.extendedProps.data.date + " " + props.appointmentData.extendedProps.data.time, 'YYYY-MM-DD LT').add(props.appointmentData.extendedProps.duration, 'minutes').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0)) &&
                                                <button className="btn btn-link p-0"
                                                    onClick={(e) => {
                                                        this.props.handleRequestForPrescreening(props.appointmentData.extendedProps.data)
                                                    }}>Remind</button>
                                            }
                                            {
                                                (props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                    props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                    props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                    props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt &&
                                                    props.appointmentData.extendedProps.data.metadata.appointment.prescreening.result) &&
                                                <div className="text-end">
                                                    <small className="d-block text-muted">Results</small>
                                                    <div>
                                                        {
                                                            (props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                                props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening.result === 'Pass') &&
                                                            <span className="text-success fw-medium">Pass</span>
                                                        }
                                                        {
                                                            (props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                                                props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt &&
                                                                props.appointmentData.extendedProps.data.metadata.appointment.prescreening.result === 'Fail') &&
                                                            <span className="text-danger fw-medium">Fail</span>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        (!(props.appointmentData && props.appointmentData.extendedProps && props.appointmentData.extendedProps.data &&
                                            props.appointmentData.extendedProps.data.metadata && props.appointmentData.extendedProps.data.metadata.appointment &&
                                            props.appointmentData.extendedProps.data.metadata.appointment.prescreening &&
                                            props.appointmentData.extendedProps.data.metadata.appointment.prescreening.attempt) &&
                                            (props.appointmentData.extendedProps.data.metadata &&
                                                props.appointmentData.extendedProps.data.metadata.appointment &&
                                                moment.duration(moment(props.appointmentData.extendedProps.data.date + " " + "11:59:59 PM", 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                                && moment.duration(moment(props.appointmentData.extendedProps.data.date + " " + "11:59:59 PM", 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0)) &&
                                        <span className="text-info fw-medium cursor-pointer" onClick={() => {
                                            this.prescreenManualVerificationAlert(props.appointmentData.extendedProps.data);
                                        }}>Manual verification</span>
                                    }
                                </li>
                            </>
                        }
                        {((this.props.userData && this.props.userData.accessEnabled && this.props.userData.accessEnabled.pms_integration &&
                            this.props.userData.clinics[0] &&
                            this.props.userData.clinics[0].writeBack === "on" && appointmentdata.currentStatus === 'scheduled') && apptPMSStatus) &&
                            <li className="list-group-item">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <small className="d-block text-muted">Appointment PMS status</small>
                                        {
                                            (appointmentdata && appointmentdata.metadata && appointmentdata.metadata.pms && appointmentdata.metadata.pms.writeBackStatus &&
                                                appointmentdata.metadata.pms.writeBackStatus === "Success") ?
                                                <select className="form-select" defaultValue={appointmentdata.metadata.pms.appointmentStatus ? appointmentdata.metadata.pms.appointmentStatus : ""} onChange={(e: any) => {
                                                    if (e.target.value === "") return;
                                                    this.handlePMSStatusChange(appointmentdata, appointmentdata.metadata.pms.appointmentStatus, e.target.value);
                                                }}>
                                                    <option value="" disabled>Not available</option>
                                                    {
                                                        this.props.masterPMSStatusList && this.props.masterPMSStatusList.map((item: any) => {
                                                            return <option value={item.status} key={item.id + "" + item.status}>{item.status}</option>
                                                        })
                                                    }
                                                </select> : <label>Pending</label>
                                        }
                                    </div>
                                </div>
                            </li>
                        }
                    </ul>
                    <div className="quick-access-cal">
                        <ul>
                            {/* quick chat */}
                            {
                                (appointmentdata.type && appointmentdata.type === "slot") &&
                                <li>
                                    {
                                        (appointmentdata.mode === "partial" && moment(appointmentdata.metadata.date + " " + appointmentdata.metadata.endTime, 'YYYY-MM-DD LT').isSameOrAfter(moment().format('YYYY-MM-DD LT'))) &&
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    Remove block time
                                                </Tooltip>
                                            }
                                        >
                                            <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                this.removeBlockTime(appointmentdata);
                                            }}>
                                                <i className="bi bi-calendar-x"></i>
                                            </button>
                                        </OverlayTrigger>
                                    }
                                    {
                                        (appointmentdata.mode === "full" && moment(appointmentdata.date, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'))) &&
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    Remove block time
                                                </Tooltip>
                                            }
                                        >
                                            <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                this.removeBlockTime(appointmentdata);
                                            }}>
                                                <i className="bi bi-calendar-x"></i>
                                            </button>
                                        </OverlayTrigger>
                                    }
                                </li>
                            }
                            {
                                (!appointmentdata?.type || (appointmentdata?.type && appointmentdata.type !== "slot")) &&
                                <>
                                    {
                                        <li>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="tooltip">Note</Tooltip>}
                                            >
                                                <button
                                                    className="btn rounded-circle btn-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            showNoteModal: true,
                                                            currentActiveModalAppointment: props.appointmentData.extendedProps.data
                                                        });
                                                    }}
                                                >
                                                    <i className="bi bi-pencil-square"></i>
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    }
                                    {
                                        (appointmentdata.dependant.phone || appointmentdata.dependant.homephone || appointmentdata.patient.phone) &&
                                        <li>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="tooltip">Voice Call</Tooltip>}
                                            >
                                                <button
                                                    className="btn rounded-circle btn-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                        this.handleVoiceCall(props.appointmentData.extendedProps.data);
                                                    }}
                                                >
                                                    <i className="bi bi-telephone"></i>
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    }
                                    {
                                        (appointmentdata.dependant && (((appointmentdata.dependant.isSignInUser === true && appointmentdata.patient.fcmtoken) || appointmentdata.dependant.phone) || ((appointmentdata.patient.isSignInUser === true && appointmentdata.patient.fcmtoken) || appointmentdata.patient.phone)) &&
                                            (appointmentdata.currentStatus === 'requested' || appointmentdata.currentStatus === 'scheduled' || (appointmentdata.currentStatus === "completed" &&
                                                !(appointmentdata.billingdata && appointmentdata.billingdata.clinicToPatientBilling &&
                                                    appointmentdata.billingdata.clinicToPatientBilling.status === "paid")))) &&
                                        <li>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        Quick chat
                                                    </Tooltip>
                                                }
                                            >
                                                <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                    this.setState({ visible: false });
                                                    this.handleChatModal(props.appointmentData.extendedProps.data)
                                                }}>
                                                    <i className="bi bi-chat-right-text"></i>
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    }
                                    {
                                        ((appointmentdata.currentStatus === 'waitlist' || appointmentdata.currentStatus === 'requested' || appointmentdata.currentStatus === 'scheduled') &&
                                            appointmentdata.dependant && !appointmentdata.dependant.phone && !appointmentdata.patient.phone) &&
                                        <li>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        Manual followup
                                                    </Tooltip>
                                                }
                                            >
                                                <button type="button" className="btn rounded-circle btn-secondary" onClick={() => { }}>
                                                    <i className="bi bi-telephone"></i>
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    }
                                    {
                                        ((appointmentdata.currentStatus === 'waitlist' || appointmentdata.currentStatus === 'requested' || appointmentdata.currentStatus === 'scheduled'
                                            || appointmentdata.currentStatus === 'completed') && appointmentdata.dependant &&
                                            appointmentdata.dependant.isSignInUser === true &&
                                            this.props.userData.accessEnabled &&
                                            this.props.userData?.accessEnabled?.digitaldoc === true) &&
                                        <li>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        Digital forms
                                                    </Tooltip>
                                                }
                                            >
                                                <button onClick={() => {
                                                    this.handleDigitalDocClick(appointmentdata);
                                                }} className="btn rounded-circle btn-secondary">
                                                    <i className="bi bi-file-medical"></i>
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    }
                                    {
                                        <li className="has-more-access">
                                            <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                this.setState({
                                                    showMore: {
                                                        open: this.state.showMore?.open ? false : true,
                                                        id: appointmentdata.id
                                                    }
                                                })
                                            }}>
                                                <i className="bi bi-three-dots"></i>
                                            </button>
                                            {
                                                this.state.showMore?.open &&
                                                <div className="quick-access-more">
                                                    {/* Verify att */}
                                                    {
                                                        // (appointmentdata.currentStatus === 'requested' || appointmentdata.currentStatus === 'scheduled') &&
                                                        <>
                                                            {/* cancel appointment */}
                                                            {
                                                                ((appointmentdata.currentStatus === 'waitlist' || appointmentdata.currentStatus === 'requested' || (appointmentdata.currentStatus === 'scheduled' && takeWritebackBool && apptCancel))) &&
                                                                <li>
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                Cancel appointment
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                                            this.setState({ showCancelModal: true, currentActiveModalAppointment: appointmentdata });
                                                                        }}>
                                                                            <i className="bi bi-calendar-x"></i>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </li>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        (appointmentdata.currentStatus === 'scheduled' && apptComplete &&
                                                            (takeWritebackBool || tempWriteBack) &&
                                                            moment(appointmentdata.date + " " + appointmentdata.time, 'YYYY-MM-DD LT').isBefore(moment()) &&
                                                            !(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                appointmentdata.metadata.appointment.confirmation &&
                                                                appointmentdata.metadata.appointment.confirmation.status === 'cancelled') &&
                                                            !(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                appointmentdata.metadata.appointment.prescreening &&
                                                                appointmentdata.metadata.appointment.prescreening.attempt &&
                                                                appointmentdata.metadata.appointment.prescreening.result === 'Fail')) &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Mark Appt. Complete
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                                    this.verifyAppointment(appointmentdata);
                                                                }}>
                                                                    <i className="bi bi-patch-check"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {
                                                        ((appointmentdata.currentStatus === 'waitlist' || appointmentdata.currentStatus === 'requested') && apptScheduled) &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Schedule appointment
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn rounded-circle btn-secondary" key='Schedule Appointment' id='Schedule Appointment'
                                                                    onClick={() => {
                                                                        this.scheduleAppointmentActionClick(appointmentdata);
                                                                    }}>
                                                                    <i className="bi bi-calendar-check"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {
                                                        (appointmentdata.currentStatus === 'scheduled' && apptReSchedule &&
                                                            (!(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                appointmentdata.metadata.appointment.prescreening &&
                                                                appointmentdata.metadata.appointment.prescreening.attempt) &&
                                                                !(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                    appointmentdata.metadata.appointment.prescreening &&
                                                                    appointmentdata.metadata.appointment.prescreening.verifiedOn))
                                                        ) &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Reschedule appointment
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn rounded-circle btn-secondary" key='ReSchedule Appointment' id='ReSchedule Appointment'
                                                                    onClick={() => {
                                                                        this.scheduleAppointmentActionClick(appointmentdata);
                                                                    }}>
                                                                    <i className="bi bi-calendar4-week"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {/* update appointment */}
                                                    {
                                                        ((appointmentdata.currentStatus === 'waitlist' || appointmentdata.currentStatus === 'requested' || appointmentdata.currentStatus === 'scheduled') &&
                                                            !(!this.state.editedDate && !this.state.editedTime && !this.state.endEditedTime) &&
                                                            (!(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                appointmentdata.metadata.appointment.prescreening &&
                                                                appointmentdata.metadata.appointment.prescreening.attempt)) &&
                                                            apptEdit &&
                                                            (takeWritebackBool || tempWriteBack)) &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Update appointment
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn rounded-circle btn-success" key='Update' id='Update' onClick={() => {
                                                                    this.handleUpdate(props.appointmentData.extendedProps.data, props)
                                                                }}>
                                                                    <i className="bi bi-check2"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {
                                                        ((takeWritebackBool || tempWriteBack) && apptConfirmPatient && (appointmentdata.currentStatus === 'scheduled' &&
                                                            moment(moment(appointmentdata.date + " " + appointmentdata.time, 'YYYY-MM-DD LT').add(appointmentdata.duration, 'minutes').format('YYYY-MM-DD LT')).isAfter(moment().format('YYYY-MM-DD LT'))
                                                            && (!(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                appointmentdata.metadata.appointment.prescreening &&
                                                                appointmentdata.metadata.appointment.prescreening.attempt &&
                                                                appointmentdata.metadata.appointment.prescreening.result === 'Fail') &&
                                                                !(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                    appointmentdata.metadata.appointment.prescreening &&
                                                                    appointmentdata.metadata.appointment.prescreening.attempt) &&
                                                                !(appointmentdata.metadata && appointmentdata.metadata.appointment &&
                                                                    appointmentdata.metadata.appointment.confirmation &&
                                                                    (appointmentdata.metadata.appointment.confirmation.status === 'confirmed' || appointmentdata.metadata.appointment.confirmation.status !== 'cancelled'))))
                                                        ) &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Appt. confirmed by patient
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                                    this.handleConfirmByPatient(appointmentdata);
                                                                }}>
                                                                    <i className="bi bi-patch-check"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {
                                                        (this.props.userData?.accessEnabled?.pms_integration === true && (appointmentdata.currentStatus === 'requested' ||
                                                            appointmentdata.currentStatus === 'waitlist')) &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Archive
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn rounded-circle btn-secondary"
                                                                    onClick={(e) => { this.markApptDone(appointmentdata); }}>
                                                                    <i className="bi bi-patch-check"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {
                                                        moment(moment(appointmentdata.date + " " + appointmentdata.time, 'YYYY-MM-DD LT').add(appointmentdata.duration, 'minutes').format('YYYY-MM-DD LT')).isBefore(moment().format('YYYY-MM-DD LT')) &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Review
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn rounded-circle btn-secondary"
                                                                    onClick={(e) => { this.manualReview(appointmentdata); }}>
                                                                    <i className="bi bi-hand-index-thumb"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {
                                                        !appointmentdata.billingdata.clinicToPatientBilling && this.props.userData.clinics[0]?.payment_feature && appointmentdata?.currentStatus === 'completed' &&
                                                        <li>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={<Tooltip id="tooltip">Create Invoice</Tooltip>}
                                                            >
                                                                <button
                                                                    className="btn rounded-circle btn-secondary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        if (this.props.paymentState && this.props.paymentState?.fetchInitPaymentResponse) {
                                                                            const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === appointmentdata.clinic.uniqueid });
                                                                            if (clinic) {
                                                                                this.setState({
                                                                                    viewPaymentModal: true,
                                                                                    invoiceModalOperation: "create",
                                                                                    currentActiveModalAppointment: {
                                                                                        ...appointmentdata,
                                                                                        clinic: {
                                                                                            ...appointmentdata.clinic,
                                                                                            metadata: clinic.metadata
                                                                                        }
                                                                                    },
                                                                                });
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="bi bi-plus-square"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </li>
                                                    }
                                                    {
                                                        appointmentdata.billingdata.clinicToPatientBilling && this.props.userData.clinics[0]?.payment_feature && appointmentdata?.currentStatus === 'completed' &&
                                                        <>
                                                            <li>
                                                                <OverlayTrigger
                                                                    placement="right"
                                                                    overlay={<Tooltip id="tooltip">View Invoice</Tooltip>}
                                                                >
                                                                    <button
                                                                        className="btn rounded-circle btn-secondary"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.paymentState && this.props.paymentState?.fetchInitPaymentResponse) {
                                                                                const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === appointmentdata.clinic.uniqueid });
                                                                                if (clinic) {
                                                                                    this.setState({
                                                                                        viewPaymentModal: true,
                                                                                        invoiceModalOperation: "view",
                                                                                        currentActiveModalAppointment: {
                                                                                            ...appointmentdata,
                                                                                            clinic: {
                                                                                                ...appointmentdata.clinic,
                                                                                                metadata: clinic.metadata
                                                                                            }
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-eye"></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </li>
                                                            <li>
                                                                <OverlayTrigger
                                                                    placement="right"
                                                                    overlay={<Tooltip id="tooltip">Edit Invoice</Tooltip>}
                                                                >
                                                                    <button
                                                                        className="btn rounded-circle btn-secondary"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.paymentState && this.props.paymentState?.fetchInitPaymentResponse) {
                                                                                const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === appointmentdata.clinic.uniqueid });
                                                                                if (clinic) {
                                                                                    this.setState({
                                                                                        viewPaymentModal: true,
                                                                                        invoiceModalOperation: "edit",
                                                                                        currentActiveModalAppointment: {
                                                                                            ...appointmentdata,
                                                                                            clinic: {
                                                                                                ...appointmentdata.clinic,
                                                                                                metadata: clinic.metadata
                                                                                            }
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-pencil"></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </li>
                                                        </>
                                                    }
                                                    {
                                                        appointmentdata.billingdata.clinicToPatientBilling && this.props.userData.clinics[0]?.payment_feature && appointmentdata?.currentStatus === 'completed' &&
                                                        <>
                                                            {
                                                                !appointmentdata.metadata.appointment.payment ?
                                                                    <li>
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={<Tooltip id="tooltip">Payment Request</Tooltip>}
                                                                        >
                                                                            <button
                                                                                className="btn rounded-circle btn-secondary"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    this.handlePaymentRequest(appointmentdata);
                                                                                }}
                                                                            >
                                                                                <i className="bi bi-cursor"></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </li> : <li>
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={<Tooltip id="tooltip">Resend Request</Tooltip>}
                                                                        >
                                                                            <button
                                                                                className="btn rounded-circle btn-secondary"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    this.handlePaymentRequest(appointmentdata);
                                                                                }}
                                                                            >
                                                                                <i className="bi bi-arrow-clockwise"></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </li>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        appointmentdata?.currentStatus === 'scheduled' && !this.props.userData?.accessEnabled?.pms_integration &&
                                                        <li>
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    Documents
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button type="button" className="btn rounded-circle btn-secondary" onClick={() => {
                                                                this.setState({ showDocumentsModal: true, 
                                                                    currentActiveModalAppointment: appointmentdata });
                                                            }}>
                                                                <i className="bi bi-file-earmark"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </li>
                                                    }
                                                </div>
                                            }
                                        </li>
                                    }
                                </>
                            }
                        </ul >
                    </div >
                </div >
            )
        } else return null
    }
    layout = (props: any) => {
        return (
            <AppointmentTooltip.Layout {...props} style={{ width: '300px', height: '500px' }} />
        )
    }
    handleDateChange = (currentDate: any) => {
        this.setState({
            date: currentDate
        });
        console.log(currentDate);
        if (this.state.view !== 'Week' && this.state.view !== 'Month') {
            this.props.callFetchApi(currentDate, currentDate, this.state.view);
        }
    }
    handleViewChange = (viewName: any) => {
        this.setState({ view: viewName }, () => { });
    }
    weekViewDayScaleRow = (props: any) => {
        return (
            <>
                {
                    props.children.map((item: any) => (
                        React.cloneElement(item, { className: (item.props.today ? 'today' : '') })
                    ))
                }
            </>
        )
    }
    weekViewDayScaleLayout = (props: any) => {
        const startDate = props.cellsData[0][0].startDate;
        const endDate = props.cellsData[0][6].endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        if (moment(this.previous).diff(this.startDate, 'millisecond') !== 0 && this.state.view !== 'Day' && this.state.view !== 'Month') {
            this.props.callFetchApi(startDate, endDate, this.state.view);
        }
        this.previous = props.cellsData[0][0].startDate
        return (
            <WeekView.DayScaleLayout {...props}></WeekView.DayScaleLayout>
        )
    }
    groupingPanelRowComponent = (props: any) => {
        $('.hide-date').closest('tr').next('tr').addClass('d-none');
        return (
            <GroupingPanel.Row {...props} className="hide-date text-center">
                {
                    props.children[0].map((item: any) => {
                        return (
                            React.cloneElement(item, { className: 'hide-date text-center' })
                        )
                    })
                }
            </GroupingPanel.Row>
        )
    }
    horizontalLayoutComponent = (props: any) => {
        $('.hide-date').closest('tr').next('tr').addClass('d-none');
        return (
            <GroupingPanel.HorizontalLayout {...props}></GroupingPanel.HorizontalLayout>
        )
    }
    handleDigitalDocClick = (appointment: any) => {
        this.setState({
            currentActiveModalAppointment: appointment,
            digitalDocShow: true
        });
    }
    verifyAppointmentDirect = async (appointment: any) => {
        this.props.doLoading();
        var objIndex: string = appointment.currentStatus.toUpperCase();
        const fromStatus = CHAT.FROM_STATUS[objIndex];
        const obj = {
            "appointmentId": Number(appointment.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.COMPLETED}.`,
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": fromStatus,
            "toStatus": CHAT.TO_STATUS.COMPLETED,
            "sendToCId": appointment.clinic.uniqueid,
            "dependantId": Number(appointment.dependant.uniqueid),
            "dateTime": {
                "date": appointment.date,
                "time": appointment.time
            },
            "firstName": this.props.userData.firstName,
            "lastName": this.props.userData.lastName,
        };
        try {
            const response = await this.db.collection(apiConfigs.CHAT_COLLECTION).add(obj);
            if (response) {
                response.get().then((docRef: any) => {
                    let data = {
                        ...docRef.data(),
                        mode: "create",
                        id: docRef.id
                    };
                    firestoreChangeNotificationToServer(data).then((success) => {
                        this.props.loadingSuccess(false);
                        if (this.state.view === 'Day') {
                            this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                        } else {
                            this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                        }
                        toast.success('Appointment is marked as complete.');
                    }).catch((err) => {
                        this.props.loadingSuccess(false);
                        toast.error('Appointment complete error.');
                    })
                });
            }
        } catch (err) {
            this.props.loadingSuccess(false);
            toast.error('Appointment complete error.');
        }
    }
    verifyAppointment = (appointment: any) => {
        let message = 'Are you sure you want to complete this appointment?';
        if ((!(appointment.metadata && appointment.metadata.appointment &&
            appointment.metadata.appointment.prescreening &&
            appointment.metadata.appointment.prescreening.attempt))
            && !(appointment.metadata && appointment.metadata.appointment &&
                appointment.metadata.appointment.prescreening &&
                appointment.metadata.appointment.prescreening.verifiedOn)
        ) {
            message = ' Are you sure you want to mark this appointment as complete, we see that prescreening for this appointment is in pending state.';
        }
        this.setState({ visible: false, isStartTimeEdit: false, isDateEdit: false }, () => {
            confirmAlert({
                title: '',
                message: message,
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-outline-primary',
                        onClick: () => {
                            this.verifyAppointmentDirect(appointment);
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-outline-primary',
                        onClick: () => {
                        }
                    }
                ]
            });
        });
    }
    commitChanges = async ({ added, changed, deleted }: any) => {
        let dataChange = this.state.events;
        const appointmentId = Object.keys(changed)[0];
        const appointment = this.state.events.find((item: any) => item.id === appointmentId);
        const updatedAppointment = changed[appointmentId];
        let providerId = '';
        if (this.state.view === 'Day') {
            const provider: any = this.props.clinics.find((item: any) => item.uniqueid == updatedAppointment.providerId);
            providerId = provider.id;
        } else if (this.state.view === 'Week') {
            const provider: any = this.props.clinics.find((item: any) => item.uniqueid == appointment.providerId);
            providerId = provider.id;
        }
        if (appointment.extendedProps.currentStatus === "scheduled" && appointment.extendedProps.data.metadata && appointment.extendedProps.data.metadata.appointment &&
            appointment.extendedProps.data.metadata.appointment.prescreening &&
            appointment.extendedProps.data.metadata.appointment.prescreening.attempt &&
            appointment.extendedProps.data.metadata.appointment.prescreening.result === 'Fail') {
            toast.warn("Can't reschedule prescreening failed appointments.");
            return;
        }
        if (appointment.extendedProps.currentStatus === "scheduled" && appointment.extendedProps.data.metadata && appointment.extendedProps.data.metadata.appointment &&
            appointment.extendedProps.data.metadata.appointment.prescreening &&
            appointment.extendedProps.data.metadata.appointment.prescreening.attempt) {
            toast.warn("Can't reschedule appointment after prescreening attempt.");
            return;
        }
        if (moment(updatedAppointment.startDate).isSameOrBefore(moment())) {
            toast.warn("Can't reschedule appointment after previous to current time.");
            return;
        }
        let provider = null;
        if (this.state.view === 'Day') {
            provider = this.props.clinics.find((item: any) => item.uniqueid == updatedAppointment.providerId);
        } else if (this.state.view === 'Week') {
            provider = this.props.clinics.find((item: any) => item.uniqueid == appointment.providerId);
        }
        const dayOfWeek = moment(updatedAppointment.startDate).format('ddd').toUpperCase();
        console.log("Provider", provider, updatedAppointment);
        if (provider && this.state.view === 'Day') {
            const hour = provider.hours.find((item: any) => dayOfWeek === item.id);
            if (hour.startHour === 'Closed' || hour.startHour === 'Clinic closed') {
                toast.warn("This operation is not allowed");
                return;
            }
            let isShaded = false;
            if (provider.blockTime && Array.isArray(provider.blockTime)) {
                let blocked = provider.blockTime.find((item: any) => {
                    return (moment(updatedAppointment.startDate).isSameOrAfter(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')) &&
                        moment(updatedAppointment.startDate).isSameOrBefore(moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT')))
                });
                if (blocked !== undefined) {
                    isShaded = true;
                }
            }
            if (isShaded === true) {
                toast.warn("Provider is blocked for particular time.");
                return;
            }
        }
        if (this.state.view === 'Week') {
            const hour = this.props.userData.clinics[0].hours.find((item: any) => item.id === dayOfWeek);
            if (hour.startHour === 'Closed') {
                toast.warn("This operation is not allowed");
                return;
            }
            let isShaded = false;
            if (provider.blockTime && Array.isArray(provider.blockTime)) {
                let blocked = provider.blockTime.find((item: any) => {
                    return (moment(updatedAppointment.startDate).isSameOrAfter(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')) &&
                        moment(updatedAppointment.startDate).isSameOrBefore(moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT')))
                });
                if (blocked !== undefined) {
                    isShaded = true;
                }
            }
            if (isShaded === true) {
                toast.warn("Provider is blocked for particular time.");
                return;
            }
        }
        if (appointment.extendedProps.currentStatus === "scheduled") {
            if (changed) {
                dataChange = dataChange.map((appointment) =>
                    changed[appointment.id]
                        ? { ...appointment, ...changed[appointment.id] }
                        : appointment
                );
                const date: string = moment(updatedAppointment.startDate).format("YYYY-MM-DD");
                const time: string = moment(updatedAppointment.startDate).format("LT");
                const startTime: string = moment(updatedAppointment.startDate).format("LT");
                const endTime: string = moment(updatedAppointment.endDate).format("LT");
                try {
                    let selectedClinic = this.props.userData.clinics[0];
                    let availableDoctor = checkProviderAvailable(selectedClinic, provider, date, startTime, endTime);
                    if (!availableDoctor) {
                        toast.warn("This operation is not allowed");
                        return;
                    }
                } catch (err) {
                    toast.warn("This operation is not allowed");
                    return;
                }
                let takeWritebackBool = false;
                let apptScheduled = true;
                if (this.props.userData.clinics[0] &&
                    this.props.userData?.accessEnabled?.pms_integration === true &&
                    this.props.userData.clinics[0].writeBack === "on" &&
                    appointment.extendedProps.data.metadata && appointment.extendedProps.data.metadata.pms && appointment.extendedProps.data.metadata.pms.writeBackStatus
                    && appointment.extendedProps.data.metadata.pms.writeBackStatus === "Success") {
                    takeWritebackBool = true;
                } else if (this.props.userData.clinics[0] &&
                    this.props.userData?.accessEnabled?.pms_integration === false) {
                    takeWritebackBool = true;
                }
                if (this.props.userData?.accessEnabled?.pms_integration === true) {
                    if (this.props.userData.clinics[0].writeBack === "off") {
                        apptScheduled = false;
                    } else if (this.props.userData.clinics[0].writeBack === "on" &&
                        this.props.userData.clinics[0].patientwriteback === "off" &&
                        this.props.userData.clinics[0].appointmentwriteback === "off" &&
                        this.props.userData.clinics[0].statuswriteback === "off") {
                        apptScheduled = false;
                    } else if (this.props.userData.clinics[0].writeBack === "on" &&
                        this.props.userData.clinics[0].patientwriteback === "on" &&
                        this.props.userData.clinics[0].appointmentwriteback === "off" &&
                        this.props.userData.clinics[0].statuswriteback === "off") {
                        apptScheduled = false;
                    } else if (this.props.userData.clinics[0].writeBack === "on" &&
                        this.props.userData.clinics[0].patientwriteback === "on" &&
                        this.props.userData.clinics[0].appointmentwriteback === "off" &&
                        this.props.userData.clinics[0].statuswriteback === "on") {
                        apptScheduled = false;
                    } else if (this.props.userData.clinics[0].writeBack === "on" &&
                        this.props.userData.clinics[0].patientwriteback === "off" &&
                        this.props.userData.clinics[0].appointmentwriteback === "off" &&
                        this.props.userData.clinics[0].statuswriteback === "on") {
                        apptScheduled = false;
                    } else if (this.props.userData.clinics[0].writeBack === "on" &&
                        this.props.userData.clinics[0].patientwriteback === "off" &&
                        this.props.userData.clinics[0].appointmentwriteback === "off" &&
                        this.props.userData.clinics[0].statuswriteback === "off") {
                        apptScheduled = false;
                    }
                }
                if (!takeWritebackBool) {
                    toast.warn('This operation is not allowed');
                    return;
                }
                if (!apptScheduled) {
                    toast.warn('This operation is not allowed');
                    return;
                }
                this.props.doLoading();
                checkAppointmentBook({
                    appointmentId: appointment.extendedProps.data.uniqueid,
                    provider: appointment.extendedProps.data.doctor.uniqueid,
                    date: date,
                    starttime: startTime,
                    endtime: endTime
                }).then(async (success) => {
                    if (success && success.status && success.status.error === false) {
                        var objIndex: string = appointment.extendedProps.currentStatus.toUpperCase();
                        const fromStatus = CHAT.FROM_STATUS[objIndex];
                        const obj = {
                            "appointmentId": Number(appointment.extendedProps.data.uniqueid),
                            "senderId": Number(getCookie().xpr_user_id),
                            "sender": CHAT.SENDER.USER,
                            "action": `Changed status from ${fromStatus} to ${fromStatus}.`,
                            "actionType": 'STATUS',
                            "dateTime": {
                                "date": date,
                                "time": time,
                                "startTime": startTime,
                                "endTime": endTime
                            },
                            "appointmenttype": {
                                "id": appointment.extendedProps.data.appointmentTypeId
                            },
                            "clinic": {
                                "id": appointment.extendedProps.data.clinic.uniqueid,
                                "doctor": providerId
                            },
                            "text": 'update through drag and drop',
                            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "fromStatus": fromStatus,
                            "toStatus": fromStatus,
                            "sendToCId": appointment.extendedProps.data.clinic.uniqueid,
                            "dependantId": Number(appointment.extendedProps.data.dependant.uniqueid),
                            "firstName": this.props.userData.firstName,
                            "lastName": this.props.userData.lastName,
                        };
                        print(obj);
                        this.props.doLoading();
                        try {
                            const response = await this.db.collection(apiConfigs.CHAT_COLLECTION).add(obj);
                            if (response) {
                                response.get().then((docRef: any) => {
                                    let data = {
                                        ...docRef.data(),
                                        mode: "create",
                                        id: docRef.id
                                    };
                                    firestoreChangeNotificationToServer(data).then((success) => {
                                        this.props.loadingSuccess(false);
                                        this.setState({ visible: false });
                                        this.setState({ events: dataChange });
                                        if (this.state.view === 'Day') {
                                            this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                        } else {
                                            this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                        }
                                    }).catch((err) => {
                                        toast.error('Update failed');
                                    });
                                });
                            }
                        } catch (err) {
                            this.props.loadingSuccess(false);
                            toast.error('Update failed');
                        }
                    } else {
                        this.props.loadingSuccess(false);
                    }
                }).catch((err) => {
                    this.props.loadingSuccess(false);
                    toast.error(err.msg);
                })
            }
        } else if ((appointment.extendedProps.currentStatus === "requested" ||
            appointment.extendedProps.currentStatus === "waitlist")) {
            if (changed) {
                dataChange = dataChange.map((appointment) =>
                    changed[appointment.id]
                        ? { ...appointment, ...changed[appointment.id] }
                        : appointment
                );
                const date: string = moment(updatedAppointment.startDate).format("YYYY-MM-DD");
                const time: string = moment(updatedAppointment.startDate).format("LT");
                const startTime: string = moment(updatedAppointment.startDate).format("LT");
                const endTime: string = moment(updatedAppointment.endDate).format("LT");
                var objIndex: string = appointment.extendedProps.currentStatus.toUpperCase();
                const fromStatus = CHAT.FROM_STATUS[objIndex];
                const obj = {
                    "appointmentId": Number(appointment.extendedProps.data.uniqueid),
                    "senderId": Number(getCookie().xpr_user_id),
                    "sender": CHAT.SENDER.USER,
                    "action": `Changed status from ${fromStatus} to ${fromStatus}.`,
                    "actionType": 'STATUS',
                    "dateTime": {
                        "date": date,
                        "time": time,
                        "startTime": startTime,
                        "endTime": endTime
                    },
                    "appointmenttype": {
                        "id": appointment.extendedProps.data.appointmentTypeId
                    },
                    "clinic": {
                        "id": appointment.extendedProps.data.clinic.uniqueid,
                        "doctor": providerId
                    },
                    "text": 'update through drag and drop',
                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "fromStatus": fromStatus,
                    "toStatus": fromStatus,
                    "sendToCId": appointment.extendedProps.data.clinic.uniqueid,
                    "dependantId": Number(appointment.extendedProps.data.dependant.uniqueid),
                    "firstName": this.props.userData.firstName,
                    "lastName": this.props.userData.lastName,
                };
                try {
                    let selectedClinic = this.props.userData.clinics[0];
                    let availableDoctor = checkProviderAvailable(selectedClinic, provider, date, startTime, endTime);
                    if (!availableDoctor) {
                        toast.warn("This operation is not allowed");
                        return;
                    }
                } catch (err) {
                    toast.warn("This operation is not allowed");
                    return;
                }
                this.props.doLoading();
                try {
                    const response = await this.db.collection(apiConfigs.CHAT_COLLECTION).add(obj);
                    if (response) {
                        response.get().then((docRef: any) => {
                            let data = {
                                ...docRef.data(),
                                mode: "create",
                                id: docRef.id
                            };
                            firestoreChangeNotificationToServer(data).then((success) => {
                                this.props.loadingSuccess(false);
                                this.setState({ visible: false });
                                this.setState({ events: dataChange });
                                if (this.state.view === 'Day') {
                                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                } else {
                                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                }
                            }).catch((err) => {
                                toast.error('Update failed');
                            });
                        });
                    }
                } catch (err) {
                    this.props.loadingSuccess(false);
                    toast.error('Update failed');
                }
            }
        } else {
            toast.warn('Only waitlisted or scheduled appointments can be rescheduled.');
            this.setState({ events: dataChange });
        }
    }
    render() {
        $('.hide-date').closest('tr').next('tr').addClass('d-none');
        return (
            <>
                <div className="d-flex align-items-center cal-arrow d-none">
                    <button className="btn" id="slide" type="button" onClick={() => {
                        if ($('.asd-scroll-left')[0].scrollLeft > 0) {
                            $('.asd-scroll-left').animate({ scrollLeft: '-=200' }, 300);
                        }
                    }}><i className="bi bi-chevron-left"></i></button>
                    <div>Scroll calendar</div>
                    <button className="btn" id="slide" type="button" onClick={() => {
                        $('.asd-scroll-left').animate({ scrollLeft: '+=200' }, 300);
                    }}><i className="bi bi-chevron-right"></i></button>
                </div>
                {this.state.resources[0].instances.length > 0 && this.state.events &&
                    <Paper className="clinic-schedule-sec">
                        {
                            (this.state.view === 'Day') &&
                            <Scheduler
                                data={this.state.events}
                                height={'auto'}
                            >
                                <ViewState
                                    currentViewName={this.state.view}
                                    currentDate={this.state.date}
                                    onCurrentDateChange={this.handleDateChange}
                                    onCurrentViewNameChange={this.handleViewChange}
                                />
                                <EditingState onCommitChanges={this.commitChanges}></EditingState>
                                <EditRecurrenceMenu />
                                <DayView
                                    startDayHour={7} endDayHour={23}
                                    // layoutComponent={(props) => {
                                    //     return (
                                    //         <DayView.Layout {...props} className="asd-scroll-left"></DayView.Layout>
                                    //     )
                                    // }}
                                    dayScaleLayoutComponent={(props) => {
                                        $('.hide-date').closest('tr').next('tr').addClass('d-none');
                                        return (<DayView.DayScaleLayout {...props} className={this.props.clinics.length > 6 ? "cfxwidth" : ''} cellComponent={(props) => {
                                            return (<DayView.DayScaleCell {...props} formatDate={() => { return '' }}></DayView.DayScaleCell>)
                                        }}></DayView.DayScaleLayout>)
                                    }}
                                    timeTableCellComponent={this.rowComponent}
                                    timeTableLayoutComponent={(props) => {
                                        $('.hide-date').closest('tr').next('tr').addClass('d-none');
                                        return (<DayView.TimeTableLayout {...props} className={this.props.clinics.length > 6 ? "cfxwidth" : ''}></DayView.TimeTableLayout>)
                                    }}
                                />
                                <WeekView startDayHour={7} endDayHour={23}
                                    dayScaleLayoutComponent={this.weekViewDayScaleLayout}
                                    dayScaleRowComponent={this.weekViewDayScaleRow}
                                    timeTableCellComponent={this.cellComponent}
                                ></WeekView>
                                <Appointments appointmentComponent={this.appointment} />
                                <Toolbar />
                                <TodayButton buttonComponent={(props) => {
                                    return (
                                        <TodayButton.Button {...props} className="today-button"></TodayButton.Button>
                                    );
                                }} />
                                <DateNavigator rootComponent={(props) => {
                                    return (
                                        <DateNavigator.Root {...props} className="navigator-class"></DateNavigator.Root>
                                    );
                                }} />
                                <ViewSwitcher />
                                <GroupingState grouping={this.state.grouping}></GroupingState>
                                <Resources
                                    data={this.state.resources}
                                    mainResourceName="providerId"
                                />
                                <IntegratedGrouping />
                                <AppointmentTooltip
                                    visible={this.state.visible}
                                    onVisibilityChange={(visible) => {
                                        this.setState({
                                            visible: !this.state.visible, showTime: '',
                                            editedTime: '', editedDate: '', isStartTimeEdit: false, isDateEdit: false, showEndTime: '', isEndTimeEdit: false, endEditedTime: '',
                                            showMore: {
                                                open: false,
                                                id: ''
                                            }
                                        })
                                    }}
                                    contentComponent={this.viewContent}
                                    layoutComponent={this.layout} />
                                <AppointmentForm
                                    visible={false}
                                    onVisibilityChange={() => {
                                        this.setState({ showScheduleCalendarModal: !this.state.showScheduleCalendarModal })
                                    }}
                                />
                                <GroupingPanel horizontalLayoutComponent={this.horizontalLayoutComponent} rowComponent={this.groupingPanelRowComponent} />
                                <DragDropProvider allowResize={() => { return false }} />
                            </Scheduler>
                        }
                        {
                            (this.state.view !== 'Day') &&
                            <Scheduler
                                data={this.state.events}
                                height={'auto'}
                            >
                                <ViewState
                                    currentViewName={this.state.view}
                                    currentDate={this.state.date}
                                    onCurrentDateChange={this.handleDateChange}
                                    onCurrentViewNameChange={this.handleViewChange}
                                />
                                <EditingState onCommitChanges={this.commitChanges}></EditingState>
                                <EditRecurrenceMenu />
                                <DayView
                                    startDayHour={7} endDayHour={23}
                                    timeTableCellComponent={this.rowComponent}
                                />
                                <WeekView startDayHour={7} endDayHour={23}
                                    dayScaleLayoutComponent={this.weekViewDayScaleLayout}
                                    dayScaleRowComponent={this.weekViewDayScaleRow}
                                    timeTableCellComponent={this.cellComponent}
                                ></WeekView>
                                <Appointments appointmentComponent={this.appointment} />
                                <Toolbar />
                                <TodayButton buttonComponent={(props) => {
                                    return (
                                        <TodayButton.Button {...props} className="today-button"></TodayButton.Button>
                                    );
                                }} />
                                <DateNavigator rootComponent={(props) => {
                                    return (
                                        <DateNavigator.Root {...props} className="navigator-class"></DateNavigator.Root>
                                    );
                                }} />
                                <ViewSwitcher />
                                <AppointmentTooltip
                                    visible={this.state.visible}
                                    onVisibilityChange={(visible) => {
                                        this.setState({
                                            visible: !this.state.visible, showTime: '', editedTime: '',
                                            editedDate: '', isStartTimeEdit: false, isDateEdit: false, showEndTime: '', isEndTimeEdit: false, endEditedTime: '',
                                            showMore: {
                                                open: false,
                                                id: ''
                                            }
                                        });
                                    }}
                                    contentComponent={this.viewContent}
                                    layoutComponent={this.layout} />
                                <AppointmentForm
                                    visible={false}
                                    onVisibilityChange={(props) => {
                                        this.setState({ showScheduleCalendarModal: !this.state.showScheduleCalendarModal })
                                    }}
                                />
                                <DragDropProvider allowResize={() => { return false }} />
                            </Scheduler>
                        }
                    </Paper>
                }
                {
                    this.state.showScheduleCalendarModal === true &&
                    <CreateAppointmentModal
                        date={this.state.date}
                        startTime={this.startTime}
                        endTime={this.endTime}
                        providerId={this.providerId}
                        hideModal={() => {
                            this.closeScheduleCalendarModal(this.state.date);
                        }}
                        fetchAppointment={() => {
                            if (this.state.view === 'Day') {
                                this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                            } else {
                                this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                            }
                        }}
                    ></CreateAppointmentModal>
                }
                {
                    this.state.currentActiveModalAppointment && this.state.showCancelModal &&
                    <CancelAppointmentModal appointment={this.state.currentActiveModalAppointment}
                        hideModal={() => {
                            this.setState({
                                showCancelModal: false,
                                currentActiveModalAppointment: null, showTime: '', editedTime: '',
                                editedDate: '', isStartTimeEdit: false, isDateEdit: false, showEndTime: '', isEndTimeEdit: false, endEditedTime: ''
                            });
                        }}
                        fetchAppointment={() => {
                            this.setState({ visible: false }, () => {
                                if (this.state.view === 'Day') {
                                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                } else {
                                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                }
                            });
                        }}
                    ></CancelAppointmentModal>
                }
                {
                    this.state.currentActiveModalAppointment && this.state.scheduleAppointmentModal &&
                    <ScheduleReScheduleModal appointment={this.state.currentActiveModalAppointment}
                        hideModal={() => {
                            this.setState({
                                scheduleAppointmentModal: false,
                                currentActiveModalAppointment: null, showTime: '', editedTime: '',
                                editedDate: '', isStartTimeEdit: false, isDateEdit: false, showEndTime: '', isEndTimeEdit: false, endEditedTime: ''
                            });
                        }}
                        fetchAppointment={() => {
                            this.setState({ visible: false }, () => {
                                if (this.state.view === 'Day') {
                                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                } else {
                                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                }
                            });
                        }}
                        isReschedule={this.state.currentActiveModalAppointment.currentStatus === 'scheduled' ? true : false}
                    ></ScheduleReScheduleModal>
                }
                {
                    this.state.currentActiveModalAppointment && this.state.digitalDocShow &&
                    <DigitalDoc
                        appointment={this.state.currentActiveModalAppointment}
                        hideModal={() => {
                            this.setState({ currentActiveModalAppointment: null, digitalDocShow: false });
                        }}
                        fetchAppointment={() => {
                            this.setState({ visible: false }, () => {
                                if (this.state.view === 'Day') {
                                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                } else {
                                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                }
                            });
                        }}
                    ></DigitalDoc>
                }
                {this.state.openVoiceCallOption && (
                    <ShowCallOption
                        appointment={this.state.currentActiveModalAppointment}
                        closeModal={() => {
                            this.setState({ openVoiceCallOption: false });
                        }}
                        call={(callTo: string, callType: string) => {
                            document
                                .getElementById("position-rel")
                                ?.classList.add("call-start");
                            this.setState({
                                openVoiceCall: true,
                                openVoiceCallOption: false,
                                callTo: callTo,
                                callType: callType,
                            });
                        }}
                        communicationprovider={this.props?.userData?.clinics[0].clinicSetting.communicationprovider}
                    ></ShowCallOption>
                )}
                {this.state.openVoiceCall && this.props?.userData?.clinics[0].clinicSetting.communicationprovider === "twilio" && (
                    <VoiceCall
                        appointment={this.state.currentActiveModalAppointment}
                        clientId={this.state.currentActiveModalAppointment.clinic.uniqueid}
                        callType={this.state.callType}
                        callTo={this.state.callTo}
                        closeModal={() => {
                            this.setState({
                                openVoiceCall: false,
                                callType: "",
                                callTo: "",
                            });
                        }}
                        callerId={
                            this.props?.userData?.clinics &&
                                this.props?.userData?.clinics[0] &&
                                this.props?.userData?.clinics[0].twilioCell
                                ? this.props?.userData?.clinics[0].twilioCell
                                : ""
                        }
                    ></VoiceCall>
                )}
                {this.state.openVoiceCall && this.props?.userData?.clinics[0].clinicSetting.communicationprovider === "mango" && (
                    <VoiceCallMango
                        appointment={this.state.currentActiveModalAppointment}
                        clientId={this.state.currentActiveModalAppointment.clinic.uniqueid}
                        callType={this.state.callType}
                        callTo={this.state.callTo}
                        closeModal={() => {
                            this.setState({
                                openVoiceCall: false,
                                callType: "",
                                callTo: "",
                            });
                        }}
                        callerId={
                            this.props?.userData?.clinics &&
                                this.props?.userData?.clinics[0] &&
                                this.props?.userData?.clinics[0].clinicSetting.mangoCell
                                ? this.props?.userData?.clinics[0].clinicSetting.mangoCell
                                : ""
                        }
                    ></VoiceCallMango>
                )}
                {this.state.openVoiceCall && this.props?.userData?.clinics[0].clinicSetting.communicationprovider === "goto" && (
                    <VoiceCallGoto
                        appointment={this.state.currentActiveModalAppointment}
                        clientId={this.state.currentActiveModalAppointment.clinic.uniqueid}
                        callType={this.state.callType}
                        callTo={this.state.callTo}
                        closeModal={() => {
                            this.setState({
                                openVoiceCall: false,
                                callType: "",
                                callTo: "",
                            });
                        }}
                        callerId={
                            this.props?.userData?.clinics &&
                                this.props?.userData?.clinics[0] &&
                                this.props?.userData?.clinics[0].clinicSetting.mangoCell
                                ? this.props?.userData?.clinics[0].clinicSetting.mangoCell
                                : ""
                        }
                    ></VoiceCallGoto>
                )}
                {
                    this.state.showNoteModal &&
                    <NoteModal
                        data={this.state.currentActiveModalAppointment}
                        handleCancel={() => {
                            this.setState({
                                showNoteModal: false,
                                currentActiveModalAppointment: false,
                            });
                        }} handleOk={() => {
                            this.setState({
                                showNoteModal: false,
                                currentActiveModalAppointment: false,
                            });
                            toast.success("Note added successfully");
                        }}
                    ></NoteModal>
                }
                {
                    this.state?.viewPaymentModal &&
                    <InvoiceModal viewPaymentModal={this.state?.viewPaymentModal}
                        invoiceOperation={this.state?.invoiceModalOperation}
                        selectedAppointment={this.state?.currentActiveModalAppointment || undefined}
                        servicesArray={this.props.serviceType}
                        hideModal={(recallList: boolean) => {
                            if (recallList) {
                                if (this.state.view === 'Day') {
                                    this.props.callFetchApi(this.state.date, this.state.date, this.state.view);
                                } else {
                                    this.props.callFetchApi(this.startDate, this.endDate, this.state.view);
                                }
                            }
                            this.setState({
                                viewPaymentModal: false,
                                currentActiveModalAppointment: null,
                                invoiceModalOperation: ""
                            });
                        }}
                    ></InvoiceModal>
                }

                {
                    this.state.showDocumentsModal &&
                    <DocumentUpload
                        data={this.state.currentActiveModalAppointment} 
                        type="appointment"
                        id={this.state.currentActiveModalAppointment?.uniqueid}
                        hideModal={() => {
                            this.setState({
                                showDocumentsModal: false,
                                currentActiveModalAppointment: null,
                            });
                        }}
                    />
                }
            </>
        )
    }
    componentDidUpdate() {
        $('.hide-date').closest('tr').next('tr').addClass('d-none');
    }
}
const mapStateToProps = (state: IAppState) => ({
    userData: state.userData.userDataResponse,
});
const mapDispatchToProps = (dispatch: any) => ({
    chatData: (data: any) => { dispatch(storeChatData(data)) },
    doLoading: () => { dispatch(fetchLoadingPending()); },
    loadingSuccess: (status: boolean) => { dispatch(fetchLoadingSuccess(status)); },
    logout: () => {
        dispatch(userLogout());
    },
    callWaitlistApi: () => {
        dispatch(callWaitlistApi());
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleNewCalendar);